
body {
    font-family: $font-family;

    h1, h2, h3, h4, h5, h6, ul, ol, li, p {
        margin: 0;
    }

    ul {
        padding: 0;
        list-style: none;
    }
}
.social-list {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;

    ul {
        display: flex;
        padding: 0;
        margin: 0;
        @media only screen and (max-width: 991px) {
            margin-left: auto;
        }
        @media only screen and (max-width: 767px) {
            margin: 0 auto 20px auto;
        }

        li {
            display: grid;
            align-items: center;
            justify-items: center;
            height: 48px;
            width: 48px;

            &:not(:first-child) {
                margin-left: 4px;
            }

            a {
                display: grid;
                align-items: center;
                justify-items: center;
                width: 100%;
                height: 100%;
                background-color: $color-gray-100;

                @media only screen and (max-width: 767px) {
                    background-color: $color-gray-200;
                    border-radius: 4px;
                }
                &:hover {
                    background-color: $color-gray-200;
                }
            }
        }
    }
}
table, img, blockquote, .preview, .siblings_events_block-wrapper {
    page-break-inside: avoid;
}

* {
    &:focus {
        outline: 5px auto;
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 8px !important;
        outline-color: coral !important;
    }
}

.carousel_elements_parent {
    a {
        &:focus {
            outline-offset: 0px !important;
        }
    }
}

.page {
    overflow: hidden;
}

.block_description {
    &.default_font {
        img {
            width: auto !important;
            max-width: 100% !important;
            height: auto;
        }
    }
}

.skiplinks {
    position: absolute;
    left: 0;
    top: 0;

    ul {
        li {
            a {
                position: absolute;
                left: 0;
                top: 0;
                opacity: 0;
                white-space: nowrap;
                z-index: -1;

                &:focus {
                    opacity: 1;
                    z-index: 9999;
                }
            }
        }
    }

    a {
        background: #000000;
        color: $color_white;
        font-size: 16px;
        display: inline-block;
        padding: 5px 15px;
    }
}

section {
    padding: 0;
}

.row {
    &.flex_row {
        display: flex;
        flex-wrap: nowrap;
        flex-flow: row;
    }
}

.gray_block {
    padding-top: 32px;
    margin-bottom: 32px;
    padding-bottom: 32px;

    //&.shortcuts_block {
    //    margin-bottom: 0 !important;
    //}

    &.dark_section_block {
        padding-top: 48px;
        padding-bottom: 48px;
        margin-bottom: 48px;
        background-color: #F4F7FB;
    }
}

.btn {
    line-height: 48px;
    display: inline-block;
    padding-top: 0;
    padding-bottom: 0;
}

button {
    background-color: transparent;
    padding: 0;
    display: inline-block;
    border: 0;
}

.block_heading-wrapper {
    h2, h3 {
        font-weight: 700;
    }
}

.top_header_holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;

    .logo_list {
        display: flex;

        h1 {
            margin-right: 2.5vw;

        }
    }
}

// Header
header {
    background-color: #fff;
    margin-bottom: 32px;

    &.months_change {
        margin-bottom: 0;
    }

    .top_header-wrapper {
        //height: 120px;

        .top_header {
            display: grid;
            grid-template-columns: 1fr auto;
            justify-content: space-between;
            align-items: center;
            padding: 25px 0;
            @media only screen and (max-width: 767px) {
                display: block;
            }

            .logo_list {
                display: flex;
                align-items: center;
                @media only screen and (max-width: 767px) {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    //margin-bottom: 16px;
                }

                a {
                    margin-right: 50px;
                    @media only screen and (max-width: 1200px) {
                        margin-right: 30px;
                    }
                    @media only screen and (max-width: 991px) {
                        margin-right: 20px;
                        img {
                            height: 40px;
                            width: auto;
                        }
                    }
                    @media only screen and (max-width: 767px) {
                        margin-right: 0px;
                    }

                    &:last-child {
                        margin-right: 0 !important;
                    }

                    &:not(:first-child) {
                        margin-right: 50px;
                        @media only screen and (max-width: 991px) {
                            margin-right: 20px;
                        }
                        @media only screen and (max-width: 767px) {
                            margin-right: 15px;
                        }
                    }

                    img {
                        max-height: calc(120px - 50px);
                        @media only screen and (max-width: 767px) {
                            max-height: 40px;
                            width: auto;
                        }
                    }
                }
            }

            .socials-wrapper {
                display: grid;
                grid-template-columns: 1fr auto;
                align-items: center;
                gap: 16px;
                @media only screen and (max-width: 767px) {
                    display: block;
                }

                form {
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    position: relative;
                    width: 348px;
                    @media only screen and (max-width: 1200px) {
                        width: auto;
                    }
                    @media only screen and (max-width: 767px) {
                        display: none;
                        width: 100%;
                    }

                    input[type="text"] {
                        padding: 0 50px 0 16px;
                    }

                    input[type="submit"] {
                        position: absolute;
                        top: 50%;
                        right: 15px;
                        transform: translate(0, -50%);
                        border: unset;
                        border-radius: 50%;
                        height: 32px;
                        width: 32px;
                        background-image: url('/svg/search.svg');
                        background-color: $color-white;
                        //background-size: contain;
                        background-position: center;
                        background-repeat: no-repeat;
                        transition: $transition;
                        font-size: 0;

                        &:hover {
                            filter: brightness(120%) saturate(120%);
                        }
                    }
                }

                .mobile_nav-wrapper {
                    display: none;
                    @media only screen and (max-width: 767px) {
                        //margin-top: -48px;
                        margin-top: 0px;
                        display: flex;
                        > div {
                            margin-left: auto;
                        }
                    }

                    .mobile_nav {
                        display: grid;
                        grid-template-columns: auto 1fr;
                        gap: 16px;
                        @media only screen and (max-width: 767px) {
                            gap: 5px;
                        }

                        .open_mobile_menu {

                            svg {
                                width: 20px;
                                height: 20px;
                                fill: $color-gray-600;
                            }
                        }
                    }
                }
            }

            .mobile_search {
                display: none;
                grid-column-start: 1;
                grid-column-end: 3;
                position: relative;

                input[type="submit"] {
                    position: absolute;
                    top: 50%;
                    right: 15px;
                    transform: translate(0, -50%);
                    border: unset;
                    border-radius: 50%;
                    height: 32px;
                    width: 32px;
                    background-image: url('/svg/search.svg');
                    background-color: $color-white;
                    //background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: $transition;
                    font-size: 0;

                    &:hover {
                        filter: brightness(120%) saturate(120%);
                    }
                }
            }
        }
    }

    .bottom_header-wrapper {
        box-shadow: inset 0px 1px 0px $color-gray-200, 0px 2px 4px #3A435714;
        @media only screen and (max-width: 767px) {
            display: none;
        }

        .bottom_header {

            .header_menu {
                p {
                    cursor: default !important;
                }

                a {
                    transition: $transition;
                    color: $color-gray-900
                }

                a:hover {
                    text-decoration: unset;
                    cursor: pointer;
                    //color: $theme-cta;
                }

                button {
                    background: transparent;
                    border: unset;
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }

                li, .link_content-wrapper {

                    &:hover {
                        @media only screen and (max-width: 767px) {
                            background-color: transparent !important;
                        }
                    }
                }

                & > ul ul {
                    display: none;
                    position: relative;
                    z-index: 5;
                }

                & li.has_more:hover > .link_content-wrapper .has_more_btn {
                    transform: rotate(180deg);
                }

                .has_more_btn {
                    &.active {
                        transform: rotate(180deg);
                    }
                }

                & li:hover > ul {
                    visibility: visible !important;
                    opacity: 1 !important;
                    display: block !important;
                }

                // First level menu
                & > ul {
                    display: inline;
                    position: relative;
                    transition: $transition;

                    .has_more_btn {
                        cursor: default;
                        transition: $transition;
                    }

                    & > li {
                        display: inline-flex;
                        color: $color-gray-900;
                        font: normal normal 900 14px/19px Lato;

                        & > .link_content-wrapper {
                            display: grid;
                            grid-template-columns: 1fr auto;
                            align-items: center;
                        }

                        & > .has_more > .link_content-wrapper a {
                            padding: 16px 4px 16px 0;
                        }

                        & > .link_content-wrapper a {
                            display: block;
                            padding: 16px 0;
                        }

                        & > .link_content-wrapper a:first-child {
                            padding-left: 0;
                        }

                        //&:not(:first-child){
                        //    margin-left: 24px;
                        //    @media only screen and (max-width: 767px) {
                        //        margin-left: 0;
                        //    }
                        //}

                        margin-right: 24px;

                        // Second level menu
                        & > ul {
                            background-color: $color-white;
                            border: 1px solid $color-gray-300;
                            position: absolute;
                            left: 0;
                            top: 23px;
                            @media only screen and (max-width: 767px) {
                                position: relative;
                                left: auto;
                                top: auto;
                                border: 0;
                            }

                            & > li {
                                color: $color-gray-900;
                                font: normal normal bold 14px/19px Lato;
                                position: relative;
                                background-color: $color-white;

                                .link_content-wrapper {
                                    position: relative;
                                    display: grid;
                                    grid-template-columns: 1fr auto;
                                    transition: $transition;

                                    &:hover {
                                        &:before {
                                            opacity: 1;
                                        }
                                    }

                                    span {
                                        padding: 16px 24px;
                                        display: block;
                                    }

                                    &:before {
                                        content: '';
                                        border-left: 3px solid $theme-main;
                                        height: 100%;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        transition: $transition;
                                        opacity: 0;
                                        @media only screen and (max-width: 767px) {
                                            display: none;
                                        }
                                    }

                                    & > button {
                                        position: relative;
                                    }
                                }

                                & > .link_content-wrapper:hover {
                                    background-color: $color-gray-100;
                                    cursor: pointer;

                                    a {
                                        text-decoration: unset;
                                    }
                                }

                                // Third level menu
                                & > ul {
                                    background-color: $color-gray-100;
                                    border: 1px solid $color-gray-300;
                                    position: absolute;
                                    left: 100%;
                                    top: 0;
                                    @media only screen and (max-width: 767px) {
                                        background-color: transparent;
                                        border: 0;
                                        padding-left: 24px;
                                        position: relative !important;
                                        left: 0 !important;
                                        top: auto !important;
                                        height: auto !important;
                                    }

                                    & > li {
                                        color: $color-gray-900;
                                        font: normal normal bold 14px/19px Lato;

                                        & > .link_content-wrapper {
                                            display: block;
                                            //padding: 16px 24px;
                                            transition: $transition;
                                        }

                                        & > .link_content-wrapper:hover {
                                            background-color: $color-gray-200;
                                            cursor: pointer;
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


// Pagination
.myajaxlist_paginate_box {
    display: flex;
    font-size: 14.4px;

    .arrow_box {
        width: auto !important;
        @media only screen and (max-width: 767px) {
            width: 50% !important;
        }
    }

    .arr_btn {
        color: $color-gray-900;
        background-color: $color-gray-100;
        padding-left: 24px;
        padding-right: 24px;
        font-weight: 700;
        border-radius: $border-radius-4;
        line-height: 48px;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .one_page {
        padding-top: 0 !important;
        line-height: 48px !important;
        width: 48px !important;
        height: 48px !important;
        border-radius: $border-radius-4 !important;

        &:hover {
            background-color: $color-gray-100 !important;
        }

        &.active {
            background-color: transparent !important;
            border: 1px solid $theme-cta !important;
            color: #000000 !important;
            ////background-color: $theme-main !important;
            //background-color: $theme-cta !important;
        }
    }

    .arrow_box {

        button {
            display: grid;
            justify-items: center;
            align-items: center;
            height: 48px;
        }
    }

    & > * {
        display: flex;

        &:not(:first-child) {
            margin-left: 16px;
            @media only screen and (max-width: 767px) {
                margin-left: 0;
            }
        }
    }

    .pages_box {
        display: flex;

        & > * {
            margin-left: 2px;
        }
    }
}


// Main banner
.main_banner-wrapper {
    .main_banner {
        .slide_holder {
            display: flex;
            flex-flow: row;
            flex-wrap: nowrap;
            height: 100%;

            > div {
                width: 50%;
            }

            .heading, p {
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .carousel_elements_parent-wrapper {
        position: relative;
        height: 416px;
        padding: 0;
        margin: 0;
        list-style-type: none;

        .carousel_elements_parent {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;

            > li {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                display: none;

                &:first-child {
                    display: block;
                }
            }
        }

        .carousel_arrow_right:hover,
        .carousel_arrow_left:hover {
            background-color: $color-gray-200 !important;
        }
    }

    .carousel_elements_parent-wrapper {

        .main_banner {


            overflow: hidden;

            .content_block {
                display: grid;
                gap: 32px;
                padding: 40px;

                .heading {
                    color: $color-white;
                    //font: normal normal 700 37px/50px Lato;
                    font-size: 28px;
                    line-height: 42px;
                    font-weight: 700;
                }

                .content {
                    //color: $color-gray-300;
                    color: #ffffff;
                    font: normal normal normal 18px/27px Lato;

                    p {
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    @media only screen and (max-width: 767px) {
                        color: $color_white;
                    }
                }
            }

            .content_block-wrapper {
                width: 43%;
                @media only screen and (max-width: 1200px) {
                    width: 50%;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 2;
                }
            }

            .image-wrapper {
                width: 58%;
                position: relative;
                @media only screen and (max-width: 1200px) {
                    width: 50%;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }

                .corner-left-bottom {
                    position: absolute;
                    bottom: 7%;
                    left: 7%;

                    svg {
                        width: 48px;
                        height: 48px;
                        transform: rotate(90deg);
                    }
                }

                .corner-top-right {
                    position: absolute;
                    top: 6%;
                    right: 3%;

                    svg {
                        width: 48px;
                        height: 48px;
                        transform: rotate(-90deg);
                    }
                }
            }

            .image-wrapper {
                display: grid;
                align-items: center;
                justify-items: center;
                //background-color: $theme-light-dark;
                background-color: $color-gray-100;
                max-height: 416px;

                img {
                    height: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    margin-left: -1px;
                }
            }
        }

        .nav-wrapper {
            display: grid;
            align-items: center;
            grid-template-columns: 1fr auto;
            padding: 0 40px 40px;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 2;
            width: 42%;

            .dots {
                display: grid;
                grid-template-columns: repeat(auto-fit, 12px);
                gap: 8px;

                button {
                    height: 12px;
                    width: 12px;
                    border-radius: 50%;
                    background-color: transparent;
                    border: 2px solid $color-white;
                    transition: $transition;

                    &.on {
                        background-color: $color-white;
                    }

                    .slide_number {
                        display: none;
                    }
                }
            }

            .btns {
                > div, button {
                    display: inline-block;
                    vertical-align: top;
                    margin-right: 4px;
                    width: 40px;
                    height: 40px;
                    line-height: 10px;
                    transition: $transition;

                    &.carousel_arrow_right {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

// Schools block
.schools_block-wrapper {

    .schools_block {
        @media only screen and (max-width: 991px) {
            .col-md-6 {
                width: 100%;
            }
        }

        .row {
            margin: 0 -8px;
            display: flex;
            flex-flow: wrap;
            justify-content: center;

            > * {
                padding: 0 8px;
            }
        }

        .item-list {
            //display: grid;
            //grid-template-columns: repeat(auto-fit, 200px);
            gap: $item-list-gap;
            //height: fit-content;
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;

            .item {
                display: grid;
                align-items: center;
                justify-items: center;
                gap: $item-gap;
                background-color: #fff;
                border-radius: 6px;
                border: 1px solid $color-gray-300;
                padding: 16px;
                min-height: 134px;
                color: $color-gray-900;
                //color: $theme-cta-font;
                text-align: center;
                font: normal normal 700 18px/24px Lato;
                width: calc(33.33% - 11px);
                @media only screen and (max-width: 1500px) {
                    font-size: 14.4px;
                    line-height: 21.6px;

                }
                @media only screen and (max-width: 1200px) {
                    gap: 0;
                    padding: 10px;
                    font-size: 12.4px;
                    line-height: 19.4px;
                }
                @media only screen and (max-width: 767px) {
                    width: calc(50% - 11px);
                }

                img {
                    height: 100%;
                    max-height: 40px;
                    width: 100%;
                    max-width: 40px;
                    object-fit: cover;
                }
            }
        }

        .find_school {
            display: grid;
            gap: 32px;
            background-image: url('/img/map_lublin.png');
            background-position: right;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 32px;
            border-radius: 6px;
            height: 100%;
            @media only screen and (max-width: 991px) {
                margin-top: 32px;
            }

            .banner_desc {
                padding-right: 40%;
                color: $color-gray-300;
                font-size: 18px;
                line-height: 24.3px;
                @media only screen and (max-width: 767px) {
                    padding-right: 0;
                }
            }

            .heading {
                display: inline-flex;
                align-items: center;
                grid-template-columns: 16px 1fr;
                gap: 16px;
                font-weight: bold;

                h2 {
                    font-size: 24px;
                }

                .heading-icon {
                    height: 18px;
                    width: 18px;

                    svg {
                        height: 18px;
                        width: 18px;
                    }

                    img {
                        height: 18px;
                        width: 18px;
                    }
                }

            }

            form {
                display: grid;
                position: relative;
                height: 48px;
                align-items: center;

                input[type="text"] {
                    padding: 14px 16px;
                    border: 1px solid $color-gray-200;
                    background-color: $color-white;
                    border-radius: $border-radius-6;
                    background-repeat: no-repeat;
                    background-position: left 16px center;
                    background-image: url("/svg/search.svg");
                    padding-left: 44px;
                }

                input[type="submit"] {
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    background-color: $theme-cta;
                    //color: $color-white;
                    color: $theme-cta-font;
                    position: absolute;
                    right: 4px;
                    top: 51%;
                    transform: translate(0, -50%);
                    height: calc(100% - 8px);
                    padding: 12px 24px;
                    width: fit-content;
                    transition: $transition;
                    border-radius: $border-radius-4;
                    font: normal normal bold 14px/19px Lato;
                    line-height: 0;
                    border: 2px solid $theme-cta !important;

                    &:hover {
                        //filter: brightness(120%) saturate(120%);
                        text-decoration: underline;
                    }
                }
            }

            .see_all {
                display: grid;
                grid-template-columns: auto 1fr;
                gap: 16px;

                .notice {
                    color: $color-white;
                    font: normal normal normal 13px/19px Lato;
                }
            }

            * {
                margin: 0;
            }
        }
    }
}

// Warnings
.warnings_block-wrapper {
    //padding-bottom: 32px;
    margin-bottom: 48px;
    .icon{
        margin-top: 10px;
    }
    .item-list {
        display: grid;
        gap: 16px;

        .item {
            display: grid;
            grid-template-columns: 16px 1fr;
            gap: 16px;
            padding: 16px;
            border-radius: $border-radius-6;
            border: 1px solid;
            align-items: flex-start;

            &.important {
                background-color: #FFEFEF;
                border-color: #FAE2E2;

                .heading, .content {
                    color: #6A0000;
                }
            }

            &.info {
                background-color: $color-neutral-50;
                border-color: $color-neutral-200;

                .heading, .content {
                    color: $color-neutral-900;
                }
            }

            .content {
                display: block;
                gap: 8px;

                &.important {
                    color: #6A0000;
                }

                &.info {
                    color: $color-neutral-900;
                }
                .heading{
                    padding-bottom: 0;
                }
                .heading h3, .heading h2 {
                    font-family: $font-family;
                    font-weight: bold;
                    //font: $unnamed-font-style-normal normal $unnamed-font-weight-bold 18px/24.3px $unnamed-font-family-lato;
                    letter-spacing: $unnamed-character-spacing--0-14;
                    //font-size: 21.6px;
                    //line-height: 29.16px;
                    font-size: 28px;
                    line-height: 42px;
                    font-weight: 700;
                    //margin-bottom: 24px;
                    margin-bottom: 20px;
                }

                .content {
                    font-family: $font-family;
                    //font: $unnamed-font-style-normal normal $unnamed-font-weight-normal 14.4px/21.6px $unnamed-font-family-lato;
                    letter-spacing: $unnamed-character-spacing-0;
                    font-size: 18px;
                    line-height: 27px;
                }
            }
        }
    }
}

.block_with_sidebar-wrapper {
    .col-md-3 {
        @media only screen and (max-width: 767px) {
            margin-bottom: 32px;
        }

        .row {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

aside {
    img {
        max-width: 100%;
        height: auto;
    }
}

// Block With sidebar
.block_with_sidebar-wrapper {

    > .row {
        margin: 0 -12px;

        > * {
            padding: 0 12px;
        }
    }

    .content_side {
        //display: grid;
        //gap: 48px;
        //height: fit-content;
        @media only screen and (max-width: 767px) {
            gap: 22px;
            display: block;
        }
    }

    // Sidebar
    .sidebar-wrapper {

        a {
            text-decoration: none;
        }

        .sidebar_list {

            .shortcuts-wrapper {

                .item-list {
                    display: grid;
                    gap: 16px;
                    height: fit-content;

                    .item {
                        display: grid;
                        align-items: center;
                        grid-template-columns: 40px 1fr;
                        gap: 16px;
                        transition: $transition;
                        padding: 16px;
                        //@media only screen and (max-width: 767px) {
                        //    display: block;
                        //}
                        img {
                            height: 100%;
                            max-height: 40px;
                            width: 100%;
                            max-width: 40px;
                        }

                        .heading {
                            font: normal normal bold 18px/24px Lato;
                            color: $color-gray-900;
                        }

                        .content {
                            padding-top: 4px;
                            color: $color-gray-600;
                            font: normal normal normal 14px/19px Lato;
                        }
                    }
                }
            }

            .calendar-wrapper {
                //margin: 0 0 16px 0;
                margin: 0 0 0px 0;

                .calendar_heading {
                    display: grid;
                    align-items: center;
                    grid-template-columns: 8px 1fr;
                    gap: 8px;
                    color: $color-gray-900;
                    font: normal normal bold 18px/27px Lato;
                    padding: 20px;
                    padding-bottom: 0;
                }

                .mycalendar2_datepicker .base_bg_color {

                    overflow: hidden;

                    .months_change {
                        padding-top: 16px;
                        padding-bottom: 8px;
                        background-color: transparent;
                        border-top: 1px solid $color-gray-300;
                        border-bottom: unset;
                    }
                }
            }

            .suggestions-wrapper {

                .item-list {
                    display: grid;
                    gap: 16px;
                    height: fit-content;

                    a {
                        display: grid;
                        align-items: center;
                        width: 100%;

                        img {
                            max-width: 100%;
                            height: auto;
                            border-radius: $border-radius-6;
                        }
                    }
                }
            }
        }
    }
}

// Newest Information
.newest_info_block-wrapper {
    //display: grid;
    //gap: 38px;
    //height: fit-content;
    margin-bottom: 32px;

    .item-list {
        display: grid;
        gap: $item-list-gap;

        .item {
            display: grid;
            grid-template-columns: auto 1fr;
            align-items: flex-start;
            gap: 16px $item-gap-horizontal;
            @media only screen and (max-width: 767px) {
                display: block;
                &:last-child {
                    margin-bottom: 0;
                }
                > .content {
                    padding: 20px;
                }
            }

            &:not(:last-child)::after {
                content: '';
                grid-column-start: 1;
                grid-column-end: 3;
                border-bottom: 1px solid #F4F7FB;
            }

            .preview {
                display: grid;
                align-items: center;
                justify-items: center;

                overflow: hidden;
                height: fit-content;
                //height: 108px;
                //width: 192px;
                width: 100%;
                height: 173px;
                @media only screen and (max-width: 767px) {
                    height: 173px;
                    width: 100%;
                }

                img {
                    height: 100%;
                    width: 100%;
                    //max-height: 108px;
                    //width: 192px;
                    //max-width: 100%;
                    object-fit: cover;
                    @media only screen and (max-width: 767px) {
                        max-height: 173px;
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }

            .content {
                @media only screen and (max-width: 767px) {
                    display: block;
                }

                .additions {
                    display: grid;
                    grid-template-columns: auto auto;
                    width: fit-content;
                    gap: 16px;
                    //margin-bottom: 10px;
                }
            }
        }
    }
}

img {
    @media only screen and (max-width: 991px) {
        max-width: 100% !important;
        height: auto !important;
    }
}

@media only screen and (max-width: 991px) {
    iframe, video {
        width: 100% !important;
    }
}

//.block_with_sidebar-wrapper {
//    > .row {
//        > div{
//            &.col-md-3{
//                @media only screen and (max-width: 991px) {
//                    padding-top: 48px;
//                }
//            }
//        }
//    }
//}


// Sibling Events
.siblings_events_block-wrapper {
    //display: grid;
    //gap: 38px;
    //height: fit-content;
    margin-bottom: 32px;

    .events_list-wrapper {

        .item-list {
            //display: flex;
            //flex-wrap: wrap;
            margin: 0 -8px;

            > li {
                padding: 0 8px;
                width: 33.33%;
                display: inline-block;
                vertical-align: top;
                margin-bottom: 16px;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                    margin-bottom: 20px;
                    margin-top: 0;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .item {
                display: flex;
                flex-wrap: wrap;
                background-color: #F4F7FB;

                .preview {
                    width: 100%;

                    img {
                        max-height: 173px;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .content {
                    display: grid;
                    gap: $item-content-gap;
                    padding: 48px 20px 20px;
                    width: 100%;

                    .date {
                        //display: grid;
                        align-items: center;
                        justify-items: center;
                        width: fit-content;
                        padding: 9.41px 11px;
                        text-align: center;
                        min-width: 73px;
                        position: absolute;
                        top: 129px;
                        left: 20px;
                        text-transform: uppercase;

                        span {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        .remaining_date {
                            text-align: center;
                            display: block;
                        }

                        .day {
                            font-size: 21.6px;
                            line-height: 29.14px;
                            font-weight: 700;
                            color: $color-gray-900;
                        }

                        .remaining_date {
                            font-size: 10.42px;
                            line-height: 14.07px;
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }
}


// Institutions
.institutions_block-wrapper {
    //margin-bottom: 48px;

    .block_nav-wrapper {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        gap: 16px;

        .nav-wrapper {

            .btns {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 4px;

                & > * {
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    background-color: $color-gray-100;
                    border-radius: $border-radius-4;
                    height: 48px;
                    width: 48px;

                    & * {
                        width: 100%;
                        max-width: 20px;
                        height: 100%;
                        max-height: 20px;
                    }
                }
            }
        }
    }


    .institutions_list-wrapper {

        &.carousel_section {
            overflow: hidden;
            margin-bottom: 48px;

            > ul {
                padding: 0;
                list-style: none;
                position: relative;
                white-space: nowrap;
                margin: 0 -8px;

                > li {
                    display: inline-block;
                    vertical-align: top;
                    padding: 0 8px;
                    width: 33.33%;
                    white-space: normal;
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .institutions_list-wrapper :not(.carousel_section) {

        .item-list {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(307px, 1fr));
        }
    }

    .item-list {
        // Temp Style (Must be Slider)
        margin: 0 -8px;
        list-style-type: none;
        padding: 0;

        > li {
            padding: 0 8px;
            margin-bottom: 16px;
        }

        .item {
            display: grid;
            gap: 21px;
            padding: 20px;

            //&.no_padding{
            //    padding: 0;
            //    .posted{
            //        margin-bottom: 16px;
            //    }
            //    .preview{
            //        margin-bottom: 0 !important;
            //    }
            //}

            &.no_border {
                border: 0;
            }

            .preview {
                margin-bottom: 16px;
                overflow: hidden;
                position: relative;

                img {
                    height: 151px;
                    width: 100%;
                    object-fit: cover;
                }

                .player_btn {
                    display: grid;
                    justify-items: center;
                    align-items: center;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    padding: 12px;
                    border: 2px solid $color-white;
                    border-radius: 50%;
                    background: #000000 0% 0% no-repeat padding-box;
                    opacity: 0.8;
                    transition: .3s;

                    img {
                        height: 24px;
                        width: auto;

                    }
                }

                &:hover .player_btn {
                    opacity: 1;
                }
            }

            .content {
                display: grid;
                //gap: 16px;
                gap: 10px;

                .heading-wrapper {
                    //display:grid;
                    gap: 12px;
                }

                .additional_info {
                    display: grid;
                    //gap: 8px;
                    gap: 6px;
                }
            }
        }

        &.vertical_list {
            grid-template-columns: 1fr;

            .item {
                grid-template-columns: auto 1fr;
                gap: 24px;

                .preview {
                    //width: 268px;
                    width: 100%;
                }
            }
        }
    }
}


// Multimedia
.multimedia_block-wrapper {
    margin-bottom: 32px;

    .multimedia_block {
        display: grid;
        gap: 38px;
        height: fit-content;

        .item-list {
            margin: 0 -8px;

            > li {
                display: inline-block;
                vertical-align: top;
                padding: 0 8px;
                width: 33.33%;
                margin-bottom: 16px;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                    margin-bottom: 16px;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }


            .item {
                display: grid;
                gap: $item-gap;

                .preview {
                    border-radius: $border-radius-6;
                    overflow: hidden;
                    position: relative;
                    height: 173px;
                    margin-bottom: 16px;

                    & > img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }

                    .player_btn {
                        display: grid;
                        justify-items: center;
                        align-items: center;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        padding: 12px;
                        border: 2px solid $color-white;
                        border-radius: 50%;
                        background: #000000 0% 0% no-repeat padding-box;
                        opacity: 0.8;
                        transition: .3s;

                        img {
                            height: 24px;
                            width: auto;

                        }
                    }

                    &:hover .player_btn {
                        opacity: 1;
                    }
                }

                .content {
                    display: grid;
                    gap: $item-content-gap;
                }
            }
        }
    }
}

.institutions_block-wrapper {
    .item-list {
        .item {
            .preview {
                margin-bottom: 0;
            }
        }
    }
}

.mulitmedia_gallery_list-wrapper {

    .gallery_list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(29%, 1fr));
        grid-gap: 16px;
        grid-auto-rows: minmax(173px, auto);
        grid-auto-flow: dense;

        video {
            height: 100%;
            width: 100%;
        }

        @media only screen and (max-width: 767px) {
            display: block;
            a {
                height: auto !important;
            }
            img {
                height: 215px !important;
            }
        }

        .gallery_item {
            @media only screen and (max-width: 767px) {
                display: block;
                width: 100%;
                //height: 0 !important;
                //padding-bottom: 66%;
                margin-bottom: 10px;
                &:last-child {
                    margin-bottom: 0;
                }
            }

            img {
                height: 100% !important;
                width: 100%;
                object-fit: cover;
                border-radius: $border-radius-6;
                aspect-ratio: 1.78/1;
            }

            &:first-child {
                grid-column-end: span 2;
                grid-row-end: span 2;
            }

            &:not(:first-child) {
                height: 173px;
            }
        }
    }
}


// Newsletter
.newsletter_block-wrapper {
    .block_heading-wrapper {
        padding-bottom: 0 !important;

        .heading {
            img, svg {
                margin-top: 8px;
            }

            h3 {
                font-size: 25.92px;
                line-height: 34.9px;
            }
        }
    }

    .newsletter_block {
        margin-bottom: 48px;
        padding: 52px 38px 53px 48px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
        }
        @media only screen and (max-width: 767px) {
            padding: 52px 30px;
        }

        .row {
            margin: 0 -8px;

            > * {
                padding: 0 8px;
            }
        }

        .block_heading-wrapper {
            align-items: flex-start;

            .heading {
                align-items: flex-start;
                @media only screen and (max-width: 1200px) {
                    margin-bottom: 32px;
                }
            }
        }

        form {

            .default_form {
                display: grid;
                gap: 12px;
                @media only screen and (max-width: 767px) {
                    display: block;
                }
            }

            .form_group {
                //display: grid;
                display: flex;
                align-items: center;
                justify-items: flex-end;
                grid-template-columns: 1fr 221px 129px;
                background-color: $color-white;
                border-radius: 6px;
                border: 1px solid $color-gray-200;
                overflow: hidden;
                @media only screen and (max-width: 767px) {
                    display: block;
                    border: 0;
                    background-color: transparent;
                    border-radius: 6px !important;
                }

                input[type="text"] {
                    border: unset;
                    border-right: 1px solid $color-gray-300;
                    border-radius: 0;
                    padding: 0 16px;
                    @media only screen and (max-width: 767px) {
                        margin-bottom: 16px;
                    }
                }

                input[type="submit"] {
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    background-color: $theme-cta;
                    //color: $color-white;
                    color: $theme-cta-font;
                    height: calc(100% - 8px);
                    padding: 12px 24px;
                    margin-right: 4px;
                    width: fit-content;
                    transition: $transition;
                    border: unset;
                    border-radius: $border-radius-4;
                    font: normal normal bold 14px/19px Lato;
                    line-height: 0;
                    @media only screen and (max-width: 767px) {
                        margin: 16px 0;
                        width: 100%;
                        height: 48px;
                        padding: 0 24px;
                    }
                    //&:hover{
                    //    filter: brightness(120%) saturate(120%);
                    //}
                }

                .select2-container {

                    .selection {

                        .select2-selection {
                            justify-items: flex-start;
                            padding: 13px;
                            border: unset;
                        }
                    }
                }

                .checkbox-wrapper {
                    display: grid;
                    align-items: center;
                    gap: 8px;
                    color: $color-gray-300;
                    font: normal normal normal 14px/19px Lato;
                }
            }
        }
    }
}

// Partners
.partners_block-wrapper {
    @media only screen and (max-width: 991px) {
        margin-top: 32px;
    }
    margin-bottom: 48px;

    .item-list {
        display: flex;
        //display: grid;
        //grid-template-columns: repeat(auto-fill, minmax(307px, 1fr));
        list-style-type: none;
        margin: 0 -8px;
        @media only screen and (max-width: 767px) {
            display: block;
        }

        > li {
            width: 25%;
            @media only screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 16px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        > * {
            padding: 0 8px;
        }

        .item {
            display: grid;
            align-items: center;
            justify-items: center;
            border: 1px solid $color-gray-200;
            border-radius: $border-radius-6;
            height: 100%;

            img {
                max-width: calc(100% - 40px);
                max-height: calc(100% - 40px);
                object-fit: cover;
                transition: 0.3s transform ease;

                &:hover {
                    transform: scale(0.95);
                }
            }
        }
    }
}


// Shortcuts
.shortcuts_block-wrapper {
    .heading {
        h2 {
            font-size: 28px;
            line-height: 42px;
            font-weight: 700;
        }
    }
    .shortcuts_block {
        display: grid;
        gap: 38px;

        .item-list {
            display: grid;
            //grid-template-columns: repeat(auto-fit, 416px);
            grid-template-columns: repeat(auto-fit, 32.33%);
            gap: $item-list-gap;
            @media only screen and (max-width: 1200px) {
                grid-template-columns: repeat(auto-fit, 49%);
            }
            @media only screen and (max-width: 991px) {
                display: block;
                > li {
                    display: block;
                    width: 100%;
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .item {
                display: grid;
                grid-template-columns: 8px 1fr;
                align-items: center;
                gap: 12px;
                padding: 20px;
                border: 1px solid $color-gray-200;
                border-radius: $border-radius-6;
                background-color: $color-white;
                box-shadow: 0px 2px 4px #3A43570A;
                color: $color-gray-900;
                font: normal normal bold 18px/27px Lato;
                transition: $transition;

                &:hover {
                    background-color: $color-gray-200;
                }

                img {
                    max-height: 8px;
                    max-width: 8px;
                    object-fit: cover;
                }
            }
        }
    }
}

// Page Description
.page_description {
    margin-bottom: 20px;
    color: $color-gray-900;
    font: normal normal normal 18px/27px Lato;
}

// Efekt obramowania
.schools_block {
    .item-list {
        a.item {
            position: relative;
            overflow: hidden; /* Ukryj część obramowania, która może wystawać */
            display: grid;


            &:hover {
                border: 1px solid rgba(255, 255, 255, 0);

                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 99%;
                    height: 99%;
                    border: dashed 2px var(--theme-main);
                    border-radius: 5px;
                    z-index: 3;
                }
            }
        }
    }
}

.categories_tab {


}

// Categories tabs
.categories_tabs-wrapper {
    margin-top: -8px;

    .categories_tabs {
        //margin-bottom: 24px;
        margin-bottom: 16px;
        //display: grid;
        //gap: 32px;

        .categories_tabs_list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            margin: 0;
            padding: 0;
            list-style-type: unset;

            .categories_tab {
                display: grid;
                justify-content: center;
                align-items: center;

                font: normal normal bold 14px/19px Lato;
                margin-bottom: 8px;
                padding: 12px 24px;
                transition: $transition;

                &:hover,
                &.active {
                    text-decoration: none;
                }

                @media only screen and (max-width: 767px) {
                    width: 100% !important;
                }
            }
        }
    }
}


// Content Filter
.content_filter-wrapper {
    margin-bottom: 24px;

    .content_filter {
        display: grid;
        gap: 24px;

        .filter_item-heading {
            font-weight: 400;
        }

        .filter_item {
            position: relative;

            input[type="text"] {
                padding: 0 50px 0 16px;
            }

            input[type="submit"] {
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translate(0, -50%);
                border: unset;
                border-radius: 50%;
                height: 32px;
                width: 32px;
                background-image: url('/svg/search.svg');
                background-color: $color-white;
                //background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                transition: $transition;
                font-size: 0;

                &:hover {
                    filter: brightness(120%) saturate(120%);
                }
            }
        }

        .filter_item_list {

            &.row {
                margin: -8px;

                > * {
                    padding: 8px;
                }
            }

            .search-wrapper {
                grid-column-start: 1;
                grid-column-end: 4;
            }
        }

        .selected_filters {

            .selected_filters_list {

                display: flex;
                align-items: center;
                margin: 0;
                padding: 0;
                list-style-type: unset;

                .selected_filter {
                    display: grid;
                    justify-content: center;
                    align-items: center;
                    grid-template-columns: 1fr auto;
                    gap: 6px;
                    background-color: $color-neutral-50;
                    border-radius: $border-radius-66;
                    color: $color-neutral-600;
                    font: normal normal bold 14px/19px Lato;
                    margin-bottom: 8px;
                    padding: 12px 24px;
                    transition: $transition;

                    &:not(:first-child) {
                        margin-left: 8px;
                    }

                    &.close_all {
                        background-color: $color-neutral-50;
                        color: $color-gray-900;
                        text-decoration: none;
                        cursor: pointer;
                    }

                    .fn_selected_filter_close {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}


// Footer
footer {


    .content-wrapper {
        padding: $block-padding;

        .container {
            padding: 0 24px;
        }

        .parts-list {

            > .row {
                margin: -24px;

                > div[class^='col'] {
                    padding: 24px;
                }

                .row {
                    margin: -16px;

                    > div[class^='col'] {
                        padding: 16px;
                    }
                }

            }

            .part {
                display: grid;
                gap: 24px;
                height: fit-content;

                //* {
                //    margin: 0;
                //    padding: 0;
                //}

                .heading {
                    display: grid;
                    gap: $item-gap;
                    height: fit-content;
                    font-size: 18px;
                    color: $color-white;

                    &::after {
                        content: "";
                        display: inline-block;
                        height: 3px;
                        width: 16px;
                        background-color: $theme-main;
                    }
                }

                .annotation {
                    color: $color-white;
                    font: normal normal normal 14px/19px Lato;
                }

                .contacts {
                    display: grid;
                    gap: 20px;
                    color: $color-white;
                    font: normal normal bold 14px/19px Lato;

                    & > * {
                        display: grid;
                        align-items: center;
                        grid-template-columns: 20px 1fr;
                        gap: 8px;

                        img,
                        svg {
                            max-height: 20px;
                            max-width: 20px;
                            object-fit: cover;

                            path {
                                fill: $color-white;
                            }
                        }
                    }

                    a {
                        color: $color-white;
                        font: normal normal bold 14px/19px Lato;
                    }
                }

                .menu_list-wrapper {

                    .menu_list, ul, ol {
                        display: grid;
                        //gap: 16px;
                        gap: 0px;
                        margin: 0;
                        padding: 0;
                        height: fit-content;
                        list-style-type: none;

                        a {
                            font: normal normal bold 14px/19px Lato;
                        }
                    }
                }
            }
        }
    }

    .footer_bottom {
        display: flex;
        justify-content: space-between;
        color: $color-white;
        padding: 32px 0;
        border-top: 1px solid #ffffff33;
        .link_list{
            gap: 26px;
            > .item{
                margin-left: 0 !important;
                margin-right: 0 !important;
            }
        }
        @media only screen and (max-width: 767px) {
            display: block !important;
        }

        a {
            color: $color-white;
        }

        .copyright {
            display: grid;
            gap: 16px;

            .link_list {
                display: flex;
                margin: 0;
                padding: 0;
                list-style-type: none;
                @media only screen and (max-width: 767px) {
                    flex-flow: row;
                    flex-wrap: wrap;
                    li {

                        width: 50%;
                        margin: 0 0 16px 0 !important;
                    }
                }

                & > .item:not(:first-child) {
                    margin-left: 16px;
                }

                .link:hover {

                    color: $theme-cta;
                }
            }
        }
    }
}

.footer_bottom {
    .made_by {
        img {
            height: 30px;
            width: 50px;
        }
    }
}

// Mobile Menu
.mobile_menu-wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 100;

    .mobile_menu {
        position: relative;
        display: grid;
        padding: 20px;

        & > * {
            padding-bottom: 20px;
        }

        & > *:not(:last-child) {

        }

        .content-wrapper {
            background-color: $color-white;
            display: grid;
            grid-template-columns: 1fr;
            position: relative;
            z-index: 10;

            .header {

            }

            .menu {

                a {
                    transition: $transition;
                    color: $color-gray-900
                }

                a:hover {
                    text-decoration: unset;
                    cursor: pointer;
                    color: $theme-cta;
                }

                button {
                    background: transparent;
                    border: unset;
                }

                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                }

                & > ul ul {
                    display: none;
                    position: relative;
                    visibility: hidden;
                    opacity: 0;
                    z-index: 2;
                }

                & li:hover > ul {
                    visibility: visible;
                    opacity: 1;
                    display: block;
                }

                // First level menu
                & > ul {
                    display: grid;
                    position: relative;
                    transition: $transition;

                    .has_more_btn {
                        cursor: default;
                    }

                    & > li {
                        display: flex;
                        color: $color-gray-900;
                        font: normal normal 900 14px/19px Lato;

                        & > .link_content-wrapper {
                            display: grid;
                            grid-template-columns: 1fr auto;
                            align-items: center;
                        }

                        & > .has_more > .link_content-wrapper a {
                            padding: 16px 4px 16px 0;
                        }

                        & > .link_content-wrapper a {
                            display: block;
                            padding: 16px 0;
                        }

                        & > .link_content-wrapper a:first-child {
                            padding-left: 0;
                        }

                        &:not(:first-child) {
                            margin-left: 24px;
                        }

                        // Second level menu
                        & > ul {
                            background-color: $color-white;
                            border: 1px solid $color-gray-300;
                            position: absolute;
                            left: 0;
                            top: 100%;

                            & > li {
                                color: $color-gray-900;
                                font: normal normal bold 14px/19px Lato;
                                position: relative;
                                background-color: $color-white;

                                .link_content-wrapper {
                                    position: relative;

                                    & > button {
                                        position: absolute;
                                        top: 50%;
                                        right: 16px;
                                        transform: translate(0, -50%);
                                    }
                                }

                                & > .link_content-wrapper a {
                                    display: block;
                                    padding: 16px 24px;
                                }

                                & > .link_content-wrapper a:hover {
                                    background-color: $color-gray-100;
                                    cursor: pointer;
                                }

                                // Third level menu
                                & > ul {
                                    background-color: $color-gray-100;
                                    border: 1px solid $color-gray-300;
                                    position: absolute;
                                    left: 100%;
                                    top: 0;

                                    & > li {
                                        color: $color-gray-900;
                                        font: normal normal bold 14px/19px Lato;

                                        & > .link_content-wrapper a {
                                            display: block;
                                            padding: 16px 24px;
                                            transition: $transition;
                                        }

                                        & > .link_content-wrapper a:hover {
                                            background-color: $color-gray-200;
                                            cursor: pointer;
                                        }

                                    }
                                }
                            }
                        }
                    }
                }
            }

            .footer {

            }
        }


        .mobile_menu_bg {
            position: absolute;
            left: 0;
            top: 0;
            height: 100vh;
            width: 100vw;
            background-color: #222222d1;
        }
    }
}

.downloads_item {
    display: grid;
    align-items: center;
    grid-template-columns: 20px 1fr auto;
    gap: 8px;

    padding: 16px 24px;
    width: fit-content;

    color: $color-gray-900;
    text-decoration: unset;
    font: normal normal bold 14px/19px Lato;
    transition: $transition;

    img {
        margin-bottom: 0;
    }

    span {
        color: $color-gray-900 !important;
    }

    .file_type, .file_size {
        background-color: $color-gray-100;
        padding: 4px 8px;
        border-radius: 10px;
        text-transform: uppercase;
    }

    @media only screen and (max-width: 767px) {
        width: 100%;
    }

    .additions {
        display: grid;
        align-items: center;
        grid-template-columns: auto auto;
        gap: 4px;
        align-items: center;

        .file_type,
        .file_size {
            padding: 4px 8px;
            background-color: $color-gray-100;
            border-radius: $border-radius-55;
            color: $color-gray-600;
            font: normal normal 900 10px/14px Lato;
            transition: $transition;
        }
    }

    &:hover {

        text-decoration: unset;

        .file_type,
        .file_size {
            background-color: $color_white;
            text-decoration: unset;
        }
    }
}

.block_annotation.default_font {
    .heading {
        padding-bottom: 32px;
    }
}

// Article Page | Simple Page
.article_page-wrapper,
.simple_page-wrapper {
    .block_content-wrapper {
        img {
            //display: block;
            //margin-top: 20px;
        }
    }

    //display: grid;
    //gap: 48px;
    @media only screen and (max-width: 767px) {
        display: block;
    }

    .content_side {
        > .row {
            margin-bottom: 48px;
            .newest_info_block-wrapper{
                margin-bottom: 0 !important;
            }
        }
    }

    .article_block-wrapper,
    .simple_page_block-wrapper, .gallery_block-wrapper {
        margin-bottom: 48px;
        iframe{
            width: 100%;
            @media only screen and (max-width: 1500px) {
                height: 600px;
            }
            @media only screen and (max-width: 991px) {
                height: 400px;
            }
            @media only screen and (max-width: 767px) {
                height: 300px;
            }
        }
        .article_block,
        .simple_page_block {
            //display: grid;
            //gap: 32px;

            .block_header {
                //display: grid;
                //gap: 16px;
                .heading {
                    //padding-bottom: 32px;
                    padding-bottom: 20px;
                    @media only screen and (max-width: 767px) {
                        //padding-bottom: 16px !important;
                        padding-bottom: 0px !important;
                    }
                }

                .block_tags {
                    margin-bottom: 8px;

                    .tag_list {
                        display: flex;

                        .tag {
                            display: grid;
                            align-items: center;
                            grid-template-columns: 12px 1fr;
                            gap: 8px;
                            text-transform: uppercase;
                            color: $color-gray-600;
                            font: normal normal 700 10px/14px Lato;

                            &:not(:first-child) {
                                margin-left: 16px;
                            }
                        }
                    }
                }

                .block_heading-wrapper {

                    .heading {
                        padding-bottom: unset;
                    }
                }
            }

            .block_content-wrapper {
                //display: grid;
                //gap: 32px;
                //@media only screen and (max-width: 767px) {
                //    gap: 16px;
                //}
                .block_additional_info {
                    margin-top: -8px;
                    margin-bottom: 20px;

                    .additional_info_list {
                        display: grid;
                        align-items: center;
                        grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
                        gap: 24px 40px;
                        padding: 17px 0;
                        border-top: 1px solid $color-gray-200;
                        border-bottom: 1px solid $color-gray-200;

                        .additional_info_item {
                            display: grid;
                            gap: 8px;

                            .info_title {
                                text-transform: uppercase;
                                color: $color-gray-600;
                                font: normal normal 700 10px/14px Lato;
                            }

                            .info_text {
                                color: $color-gray-900;
                                font: normal normal bold 14px/19px Lato;

                                a {
                                    color: $theme-cta;
                                    text-decoration: underline;
                                    transition: $transition;

                                    &:hover {
                                        color: $color-gray-900;
                                    }
                                }
                            }
                        }
                    }
                }

                .block_annotation {
                    color: $color-gray-900;
                    font: normal normal normal 18px/27px Lato;
                }

                .multimedia_video-wrapper {
                    display: grid;
                    width: 100%;

                    iframe {
                        width: 100%;
                    }
                }

                .block_image {
                    display: grid;
                    align-items: center;

                    img {
                        //height: 391px;
                        height: auto;
                        width: auto;
                        max-width: 100%;
                        margin: auto;
                        border-radius: 4px;
                    }
                }

                .quote {
                    display: grid;
                    align-items: flex-start;
                    grid-template-columns: 24px 1fr;
                    gap: 16px;
                    padding: 16px;
                    height: fit-content;
                    background-color: $color-neutral-50;
                    border-radius: $border-radius-6;

                    .content-wrapper {
                        display: grid;
                        gap: 16px;
                        height: fit-content;
                        color: $color-neutral-900;

                        .content {
                            font: normal normal bold 18px/27px Lato;
                        }

                        .author {
                            font: italic normal normal 14px/22px Lato;
                        }
                    }
                }

                .block_description {
                    color: $color-gray-900;
                    font: normal normal normal 18px/27px Lato;

                    a {
                        color: $color-gray-900;
                        //color: $theme-cta;
                        text-decoration: underline;
                        transition: $transition;

                        &:hover {
                            color: $color-gray-900;
                        }
                    }
                }

                .faq {

                    .list {
                        //display: grid;
                        //gap: 8px;

                        .faq_item {
                            //display: grid;
                            //
                            //height: fit-content;
                            transition: $transition;
                            margin-bottom: 8px;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &:hover .question {
                                background-color: $color-gray-100
                            }

                            &.active {
                                box-shadow: 0px 0px 16px #3A435714;

                                button {
                                    &:after {
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .question {
                                img {
                                    display: none;
                                }

                                width: 100%;
                                color: $color-gray-900;
                                padding: 16px 20px 18px;
                                font: normal normal bold 18px/27px Lato;
                                cursor: pointer;

                                button {
                                    display: flex;
                                    justify-content: space-between;
                                    text-align: left;
                                    width: 100%;
                                    -ms-flex-align: center;
                                    -webkit-align-items: center;
                                    -webkit-box-align: center;

                                    align-items: center;
                                    flex-wrap: nowrap;
                                    //@media only screen and (max-width: 767px) {
                                    //    font-size: 14px;
                                    //}
                                    &:after {

                                    }
                                }


                                button::after {
                                    content: '';
                                    transition: $transition;
                                    width: 20px;
                                    height: 20px;
                                    background-repeat: no-repeat;
                                    background-position: center;
                                    background-image: url("/svg/expand.svg");
                                }

                                //&:hover>img{
                                //    transform: rotate(180deg);
                                //}
                            }

                            .answer {
                                display: none;
                                color: $color-gray-900;
                                padding: 3px 20px 20px;
                                font: normal normal normal 18px/27px Lato;

                                p:not(:first-child) {
                                    margin-top: 1.25vh;
                                }

                            }
                        }
                    }
                }

                //Faq categories
                .faq_categories {

                    .faq_categories_list {
                        display: grid;
                        gap: 48px;

                        .faq_category_block {
                            display: grid;
                            gap: 12px;
                        }
                    }
                }


                //Map
                .map_block-wrapper {

                    iframe {
                        border-radius: $border-radius-6;
                    }
                }


                // Downloads
                .downloads {

                    .list {
                        display: grid;
                        gap: 8px;

                        > ul {
                            > li {
                                margin-bottom: 8px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }
                        }

                        @media only screen and (max-width: 767px) {
                            width: 100%;
                        }

                        //.downloads_item{
                        //    display: grid;
                        //    align-items: center;
                        //    grid-template-columns: 20px 1fr auto;
                        //    gap: 8px;
                        //
                        //    padding: 16px 24px;
                        //    width: fit-content;
                        //
                        //    color: $color-gray-900;
                        //    text-decoration: unset;
                        //    font: normal normal bold 14px/19px Lato;
                        //    transition: $transition;
                        //    img{
                        //        margin-bottom: 0;
                        //    }
                        //    .file_type, .file_size{
                        //        background-color: $color-gray-100;
                        //        padding: 4px 8px;
                        //        border-radius: 10px;
                        //        text-transform: uppercase;
                        //    }
                        //    @media only screen and (max-width: 767px) {
                        //        width: 100%;
                        //    }
                        //    .additions{
                        //        display: grid;
                        //        align-items: center;
                        //        grid-template-columns: auto auto;
                        //        gap: 4px;
                        //        align-items: center;
                        //
                        //        .file_type,
                        //        .file_size{
                        //            padding: 4px 8px;
                        //            background-color: $color-gray-100;
                        //            border-radius: $border-radius-55;
                        //            color: $color-gray-600;
                        //            font: normal normal 900 10px/14px Lato;
                        //            transition: $transition;
                        //        }
                        //    }
                        //
                        //    &:hover{
                        //
                        //        text-decoration: unset;
                        //
                        //        .file_type,
                        //        .file_size{
                        //            background-color: $color_white;
                        //            text-decoration: unset;
                        //        }
                        //    }
                        //}
                    }
                }

                .downloadable_blocks {

                    .downloadable_blocks_list {
                        //display: grid;
                        //gap: 48px;

                        .downloadable_items_block {
                            //display: grid;
                            //gap: 12px;
                        }
                    }
                }
            }
        }


        // Simple Page styles (page personal styles)
        .simple_page_block {

            .block_content-wrapper {

                .block_annotation {
                    color: $color-gray-900;
                    font: normal normal normal 18px/27px Lato;
                }
            }
        }
    }
}

.gallery_list {
    .annotation {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

// Gallery
.gallery_block-wrapper {
    .gallery_block {

        .block_content {

            .gallery_list-wrapper {

                .gallery_list {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, 200px);
                    gap: 16px;
                    @media only screen and (max-width: 767px) {
                        display: flex;
                        flex-flow: row;
                        flex-wrap: wrap;

                    }

                    .gallery_item {
                        @media only screen and (max-width: 767px) {
                            width: calc(50% - 8px);
                        }

                        img {
                            height: 113px;
                            width: 100%;
                            object-fit: cover;
                            border-radius: $border-radius-6;
                        }
                    }
                }
            }
        }
    }
}

// Article sharing
.share_article_block-wrapper {
    margin-bottom: 64px;
    clear: both;
    .share_article_block {
        padding-top: 16px;
        margin-top: 16px;
        border-top: 1px solid $color-gray-300;
        @media only screen and (max-width: 767px) {
            li, a {
                margin-bottom: 10px;
            }
        }

        .block_content {
            //display: grid;
            //grid-template-columns: 1fr auto;
            //gap: 8px;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;

            align-items: center;
            @media only screen and (max-width: 767px) {
                display: block;
            }

            .share_article_social-list {
                display: flex;
                //flex-wrap: wrap;
                flex-wrap: nowrap;
                align-items: center;
                list-style-type: none;
                gap: 8px;
                margin: 0;
                padding: 0;
                @media only screen and (max-width: 767px) {
                    display: block;
                }

                li {

                    a {
                        display: grid;
                        align-items: center;
                        justify-items: center;
                        padding: 14px;
                        background-color: $color-gray-100;
                        color: $color-gray-900;
                        font: normal normal bold 14px/19px Lato;
                        transition: $transition;
                        border-radius: $border-radius-4;

                        &:hover {
                            background-color: $color-gray-200;
                            text-decoration: unset;
                        }

                        .social_icon {
                            display: grid;
                            align-items: center;

                            &.with_text {
                                grid-template-columns: auto 1fr;
                                gap: 8px;
                            }
                        }
                    }
                }
            }

            .addtional_features-list {
                display: grid;
                align-items: center;
                grid-template-columns: auto auto;
                width: fit-content;
                gap: 8px;
                @media only screen and (max-width: 767px) {
                    display: block;
                    width: 100%;
                    text-align: center;
                }

                & > * {
                    display: grid;
                    align-items: center;
                    grid-template-columns: auto 1fr;
                    gap: 10px;
                    padding: 0px 24px;
                    background-color: $color-gray-100;
                    border-radius: $border-radius-4;
                    color: $color-gray-900;
                    font: normal normal bold 14px/19px Lato;
                    text-decoration: unset;
                    transition: $transition;
                    line-height: 48px !important;
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                        display: block;
                    }

                    &:hover {
                        cursor: pointer;
                        background-color: $color-gray-200;
                        text-decoration: unset;
                    }
                }
            }
        }
    }
}


//Related Block
.related_block-wrapper {
    margin-bottom: 48px;

    .related_block {
        display: grid;
        gap: 16px;
        padding: 48px;
        background-color: $color-gray-100;
        border-radius: $border-radius-6;

        .block_content-wrapper {

            .related_items_list {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 16px;
                height: fit-content;

                .item {
                    display: grid;
                    padding: 16px 24px;
                    gap: 8px;
                    background-color: $color-white;
                    border: 1px solid $color-gray-200;
                    border-radius: $border-radius-6;
                    height: fit-content;
                    text-decoration: none;
                    color: $color-gray-900;
                    font: normal normal bold 14px/19px Lato;
                    transition: $transition;

                    &:hover {
                        background-color: $color-gray-200;
                    }
                }
            }
        }
    }
}

// Contacts
.global_contact_block-wrapper {
    .text{
        &.default_font{
            font-size: 16px !important;
            line-height: 27px !important;
        }
    }
    .global_contact_block {

        .block_content {
            display: grid;
            grid-template-columns: 1fr 416px;
            gap: 48px;
            height: fit-content;
            margin-bottom: 50px;
            @media only screen and (max-width: 767px) {
                display: block;
                .datails {
                    margin-bottom: 32px;
                }
            }

            .content {
                display: grid;
                gap: 32px;
                height: fit-content;

                .heading {
                    display: grid;
                    gap: 16px;
                    height: fit-content;

                    * {
                        margin: 0;
                    }

                    .text {
                        color: $color-gray-600;
                        font: normal normal normal 14px/19px Lato;
                    }
                }

                .datails {
                    display: grid;
                    gap: 20px;
                    color: $color-gray-900;
                    font: normal normal bold 14px/19px Lato;

                    .location {
                        display: flex;
                        grid-template-columns: 20px 1fr;
                        gap: 8px;
                    }

                    .phones_list {
                        display: flex;
                        gap: 20px;

                        .phone_item {
                            display: grid;
                            align-items: center;
                            grid-template-columns: 20px 1fr;
                            gap: 8px;
                        }
                    }

                    .mail {
                        display: flex;
                        grid-template-columns: 20px 1fr;
                        gap: 8px;
                    }

                    .work_time {
                        display: flex;
                        gap: 8px;
                        margin-top: 12px;
                        flex-flow: column;

                        .work_time-heading {
                            color: $color-gray-600;
                            font: normal normal bold 10.42px/14.07px Lato;
                            text-transform: uppercase;
                        }

                        .work_time-content {
                            display: grid;
                            gap: 8px;
                            color: $color-gray-900;
                            font: normal normal bold 14px/19px Lato;

                            * {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            iframe {
                max-height: 301px;
                width: 100%;
            }
        }
    }
}

// Departments
.departments_block-wrapper {

    .departments_block {

        .block_content {

            .departments_list {
                display: grid;
                gap: 20px;
                padding: 32px;
                background-color: $color-gray-100;


                .item {
                    display: grid;
                    gap: 8px;

                    .name {
                        padding-bottom: 4px;
                        color: $color-gray-900;
                        font: normal normal bold 18px/24px Lato;
                    }

                    .additional_info {
                        display: grid;
                        gap: 8px;

                        .phones_list {
                            grid-template-columns: 1fr;

                            .phone_item {

                                svg {
                                    height: 12px;
                                    max-width: 12px;

                                    path {
                                        fill: $color-gray-600;
                                    }
                                }
                            }
                        }
                    }

                    &:not(:last-child) {
                        padding-bottom: 20px;
                        border-bottom: 1px solid $color-gray-300;
                    }
                }
            }
        }
    }
}

// Send message
.send_message_block-wrapper {

    .send_message_block {
        display: grid;
        gap: 32px;
        padding: 40px;
        background-color: $color-gray-100;
        @media only screen and (max-width: 991px) {
            margin-bottom: 32px;
        }
        @media only screen and (max-width: 767px) {
            display: block;
            padding: 20px;
            th {
                padding: 0 !important;
            }
            .one_multi_row {

                div.field_checkbox_input {
                    padding: 0;
                }

                td {
                    padding: 16px 0;
                }
            }
        }

        .send_message_block {
            padding: 0;

            .field_radio_handler {
                display: inline-block;
                vertical-align: top;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .block_heading-wrapper {

            .heading {
                display: grid;
                grid-template-columns: 32px 1fr;
                padding: 0;

                img {
                    height: 32px;
                    width: 32px;
                }
            }
        }

        .block_content {

            form {
                display: grid;
                gap: 24px;

                .form_group {
                    display: grid;
                    gap: 8px;

                    .label {
                        color: $color-gray-900;
                        font: normal normal normal 14px/19px Lato;
                        text-align: left;
                        padding: 0;
                    }
                }

                .checkbox-wrapper {
                    display: grid;
                    align-items: flex-start;
                    grid-template-columns: auto 1fr;
                    gap: 8px;
                }

                input[type="submit"] {
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    background-color: $theme-cta;
                    color: $color-white;
                    padding: 0px 24px;
                    margin-left: auto;
                    width: fit-content;
                    height: 48px;
                    transition: $transition;
                    border: unset;
                    border-radius: $border-radius-4;
                    font: normal normal bold 14px/19px Lato;
                    line-height: 0;

                    &:hover {
                        //filter: brightness(120%) saturate(120%);
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// Error
.error_page_block-wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 100%;
    width: 100%;
    @media only screen and (max-width: 767px) {
        display: block;
        padding: 0 15px;
    }

    .error_page_block {
        display: grid;
        justify-content: center;
        justify-items: center;
        text-align: center;
        max-width: 700px;
        padding: 60px 0;
        background-image: url('./../img/error_bg.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        @media only screen and (max-width: 767px) {
            width: 100%;
            padding: 0 15px;
            max-width: none;
            display: block;
            text-align: center;
        }

        .pencil_img {
            margin-bottom: 24px;
            @media only screen and (max-width: 767px) {
                img {
                    max-width: 100%;
                    height: auto;
                    width: auto;
                }
            }
        }

        .error_type {
            margin-bottom: 16px;
            display: grid;
            background-color: $color-gray-600;
            color: $color-white;
            text-align: center;
            font: normal normal 700 18px/24px Lato;
            width: fit-content;
            border-radius: $border-radius-44;
            padding: 8px 24px;
            @media only screen and (max-width: 767px) {
                margin: 0 auto 16px auto;
            }
        }

        .block_heading-wrapper {
            display: grid;
            justify-items: center;
            justify-content: center;
            color: $color-gray-900;
            text-align: center;
            font: normal normal 900 45px/60px Lato;
            //margin-bottom: 24px;

            * {
                margin: 0;
            }
        }

        .description {
            color: $color-gray-600;
            text-align: center;
            font: normal normal normal 18px/27px Lato;
            margin-bottom: 40px;
        }

        .btn {
            color: $color-white;
            text-align: left;
            font: normal normal bold 14px/19px Lato;
            margin: 0 auto;
        }
    }
}


// Sitemap
.sitemap_block-wrapper {
    //display: grid;
    gap: 24px;

    .content {

        .sitemap_list {
            display: grid;
            gap: 32px;

            .sitemap_item {
                display: grid;
                gap: 24px;

                .heading {
                    color: $color-gray-900;
                    text-align: left;
                    font: normal normal 900 18px/24px Lato;
                }

                ul {
                    margin: 0;
                    padding: 0;
                    display: grid;
                    gap: 16px;
                    list-style-type: none;

                    li {
                        display: grid;
                        align-items: center;
                        grid-template-columns: 4px 1fr;
                        gap: 12px;

                        &::before {
                            content: '';
                            height: 4px;
                            width: 4px;
                            background-color: $color-neutral-600;
                            border-radius: 50%;
                        }

                        ul {
                            padding-left: 16px;
                            grid-column-start: 1;
                            grid-column-end: 3;
                        }
                    }
                }
            }
        }
    }
}

//Searhig results
.search_results_block-wrapper {
    display: grid;
    gap: 32px;

    .heading {
        margin-bottom: 16px;
    }

    .block_heading-wrapper {
        padding: 0;
    }

    .form-wrapper {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr auto;
        background-color: $color-white;
        border: 1px solid $color-gray-300;
        border-radius: $border-radius-6;
        padding-bottom: 0;
        width: 100%;

        form {

            .form_group {
                display: grid;
                align-items: center;
                grid-template-columns: 1fr auto;
                width: 100%;

                .fn_clear {
                    display: grid;
                    justify-items: center;
                    align-items: center;
                    position: absolute;
                    right: 4px;
                    top: 50%;
                    transform: translate(0, -50%);
                    background-color: transparent;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    transition: $transition;

                    &:hover {
                        background-color: $color-gray-100;
                        cursor: pointer;
                    }
                }

                .input-wrapper {
                    border-right: 1px solid $color-gray-300;

                    input {
                        border: unset;
                    }
                }

                input[type="submit"] {
                    display: grid;
                    align-items: center;
                    justify-items: center;
                    background-color: $theme-cta;
                    color: $color-white;
                    height: calc(100% - 8px);
                    padding: 12px 24px;
                    margin: 4px;
                    width: fit-content;
                    transition: $transition;
                    border: unset;
                    border-radius: $border-radius-4;
                    font: normal normal bold 14px/19px Lato;
                    line-height: 0;
                    margin-left: 16px;

                    &:hover {
                        text-decoration: underline !important;
                        //filter: brightness(120%) saturate(120%);
                    }
                }
            }
        }
    }

    .search_results_block_list-wrapper {
        .categories_tabs-wrapper {
            .categories_tabs {
                margin-bottom: 0 !important;
            }
        }

        .block_heading-wrapper {
            margin-bottom: 0;

            .heading {
                margin-bottom: 0;
            }
        }

        .item-list {
            display: grid;
            gap: 48px;

            .block_heading-wrapper {
                display: grid;
                grid-template-columns: 1fr auto;
                justify-content: space-between;
            }

            .search_results_block_item_list-wrapper {

                .item-list {
                    display: grid;
                    gap: 8px;

                    .item {
                        display: grid;
                        padding: 16px;


                        .category {
                            margin-bottom: 14px;
                        }

                        .heading {
                            margin-bottom: 16px;

                        }

                        .content {
                            margin-bottom: 20px;

                            p {
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }

                        .posted {
                            grid-template-columns: 1fr;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}

.posted {
    height: 15px;
}

// Quiz

.active_form {
    .err_txt {
        color: $color_err;
        font: normal normal normal 13px/19px Lato;
    }
}

.data_saved {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    padding: 23px 20px;
    background-color: #E0F5DC;
    border-radius: 10px;
    margin-bottom: 30px;
    color: #0C152C;

    span {
        padding: 5px 0 5px 39px;
        background-repeat: no-repeat;
        background-position: left center;
        background-image: url("./../img/saved_icon.svg");
    }
}

.asterisk:after {
    content: " *";
    color: red;
}

.quiz_block-wrapper {

    .quiz_block {
        display: grid;
        gap: 32px;
        padding: 40px;
        background-color: $color-gray-100;
        border-radius: $border-radius-6;

        table {
            tbody {
                background-color: $color-white;

                tr {
                    border-color: $color-gray-300;
                }
            }
        }

        & > .block_heading-wrapper {

            .heading {
                grid-template-columns: 32px 1fr;

                img {
                    height: 32px;
                    width: auto;
                }
            }
        }

        .content {

            form {
                display: grid;
                gap: 32px;

                & > *:not(:last-child) {
                    padding-bottom: 32px;
                    border-bottom: 1px solid $color-gray-300;
                }

                input {
                    color: $color-gray-900;
                    font: normal normal normal 14px/19px Lato;
                }

                .block_heading {
                    display: grid;
                    gap: 8px;

                    .heading {
                        color: $color-gray-900;
                        font: normal normal 900 18px/24px Lato;
                        margin: 0;
                    }

                    .annotation {
                        color: $color-gray-600;
                        font: normal normal normal 14px/19px Lato;
                    }
                }

                .content {
                    display: grid;
                    gap: 24px;
                }

                .form_group {
                    display: grid;
                    gap: 4px;

                    .group_label {
                        padding-bottom: 4px;
                        width: fit-content;
                        color: $color-gray-900;
                        font: normal normal normal 14px/19px Lato;

                        &.required_group::after {
                            content: "*";
                            color: $color_err;
                            margin-left: 4px;
                        }
                    }

                    .addition_to_input {
                        color: $color-gray-600;
                        font: normal normal normal 13px/19px Lato;
                    }

                    .err_txt {
                        color: $color_err;
                        font: normal normal normal 13px/19px Lato;
                    }
                }

                .form_btns {
                    display: grid;
                    grid-template-columns: auto auto;
                    justify-content: space-between;
                }

                .default_form .field_checkbox .field_checkbox_box .checkbox_image_box > div {
                    border: 2px solid $color-gray-400;
                    background-color: $color-white;

                    &.yes {
                        background-color: $theme-cta;
                    }
                }

                .personal_data_block-wrapper {

                    .personal_data_block {
                        display: grid;
                        gap: 24px;

                        .content {
                            display: grid;
                            gap: 24px;
                        }
                    }
                }

                .additional_info_block-wrapper {

                    .additional_info_block {
                        display: grid;
                        gap: 24px;

                        .content {
                            display: grid;
                            gap: 24px;
                        }
                    }
                }
            }
        }
    }
}

.item {
    > .content {
        .additions {
            > div {
                margin-bottom: 12px;
            }
        }

        img {
            //margin-top: 2px;
            margin-right: 4px;
        }

        .category {
            svg {
                margin-right: 4px;
            }
        }
    }
}

.location {
    font-size: 12.5px;
    line-height: 18.75px;
}

// ___________ RWD styles ____________


@media screen and (max-width: 1200px) {

    .share_article_block-wrapper {

        .share_article_block {

            .block_content {
                grid-template-columns: 1fr;
            }
        }
    }

    // Main Banner
    .main_banner-wrapper {

        .carousel_elements_parent-wrapper {

            .main_banner {
                grid-template-columns: unset;
                width: 100%;
                min-height: 416px;

                .content_block-wrapper {

                    height: 100%;
                    //background-color: #2222227a;
                    color: #fff;
                    backdrop-filter: blur(2px);

                    .content_block {

                        .heading {
                            font-size: 39px;
                            @media only screen and (max-width: 991px) {
                                font-size: 26px;
                                line-height: 120%;
                            }
                        }
                    }
                }

                .image-wrapper {
                    min-height: 400px;
                }
            }

            .nav-wrapper {
                width: 50%;
                @media only screen and (max-width: 767px) {
                    width: 100%;
                }
            }
        }
    }
}

//
//
//@media screen and (max-width:991px) {
//
//    //Header
//    header{
//
//        .top_header-wrapper{
//
//            .top_header{
//
//                .socials-wrapper{
//                    grid-template-columns: 1fr;
//
//                    form{
//                        display: none;
//                    }
//
//                    .social-list{
//                        display: none;
//                    }
//
//                    .mobile_nav-wrapper{
//                        display: block;
//                    }
//                }
//            }
//        }
//
//    }
//
//    //Schools block
//    .schools_block-wrapper{
//
//        .schools_block{
//            grid-template-columns: 1fr;
//
//            .item-list{
//                grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
//            }
//        }
//    }
//
//
//    //Block with sidebar
//    .block_with_sidebar-wrapper{
//        grid-template-columns: 1fr;
//
//    }
//
//
//    //Siblings Events
//    .siblings_events_block-wrapper{
//
//        .events_list-wrapper{
//
//            .item-list{
//                grid-template-columns: repeat(auto-fit, minmax(308px, 1fr));
//            }
//        }
//
//    }
//
//
//    //Multimedia
//    .multimedia_block-wrapper{
//
//        .multimedia_block{
//
//            .item-list{
//                grid-template-columns: repeat(auto-fit, minmax(308px, 1fr));
//            }
//        }
//    }
//
//
//    //Shortcuts
//    .shortcuts_block-wrapper{
//
//        .shortcuts_block{
//
//            .item-list{
//                grid-template-columns: repeat(auto-fit, minmax(416px, 1fr));
//            }
//        }
//    }
//
//
//    //Related items
//    .related_block-wrapper{
//
//        .related_block{
//
//            .block_content-wrapper{
//
//                .related_items_list{
//                    grid-template-columns: 1fr;
//                }
//            }
//        }
//    }
//
//    //NewsLetter
//    .newsletter_block-wrapper{
//        .newsletter_block{
//            margin: 0 -8px;
//
//            &>*{
//                padding: 0 8px;
//            }
//
//            form{
//
//                .form_group{
//                    background-color: transparent;
//                    border: unset;
//                    grid-template-columns: 1fr;
//                    gap: 8px;
//
//                    input[type="text"]{
//                        border-radius: $border-radius-4;
//                    }
//
//                    input[type="submit"]{
//                        height: 43px;
//                        width: 157px;
//                        margin: 0;
//                        @media only screen and (max-width: 991px) {
//                            margin-top: 16px;
//                            width: 100%;
//                        }
//                    }
//                }
//            }
//        }
//    }
//}
//@media screen and (max-width: 991px) {
//    .content {
//        font-size: 16px;
//
//        p {
//            display: -webkit-box;
//            -webkit-line-clamp: 6;
//            -webkit-box-orient: vertical;
//            overflow: hidden;
//            text-overflow: ellipsis;
//
//        }
//    }
//}

@media screen and (max-width: 767px) {
    header {
        .container {
            width: 100%;

            .logo_list {


            }
        }
    }

    .main_banner-wrapper {

        .carousel_elements_parent-wrapper {

            .main_banner {

                .content_block-wrapper {

                    .content_block {

                        .heading {
                            font-size: 26px;
                            line-height: 120%;
                        }

                        .content {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    // Pagination
    .myajaxlist_paginate_box {
        display: flex;
        flex-wrap: wrap;
        @media only screen and (max-width: 767px) {
            flex-flow: row;
            flex-wrap: wrap !important;
            .pages_box {
                order: 1 !important;
            }
            .left_arr {
                order: 2 !important;
            }
            .right_arr {
                order: 3 !important;
            }
        }

        * {
            margin: 0;
        }

        .arrow_box {
            order: 2;
            width: calc(50% - 16px);
            display: flex;
            justify-content: center;
        }

        .pages_box {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 2px;
            order: 0;
            width: 100%;
            @media only screen and (max-width: 767px) {
                margin-bottom: 10px;
            }
        }
    }

    //Institutions
    .block_nav-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }


    //Related Items
    .related_block-wrapper {

        .related_block {
            padding: 16px;
        }
    }

    //Shortcuts
    .shortcuts_block-wrapper {

        .shortcuts_block {

            .item-list {
                grid-template-columns: 1fr;
            }
        }
    }
}

//
//.block_with_sidebar-wrapper{
//    margin-bottom: 48px;
//    @media only screen and (max-width: 991px) {
//        margin-bottom: 28px;
//    }
//    @media only screen and (max-width: 767px) {
//        margin-bottom: 32px;
//    }
//}

.element_list {
    margin: 0 -8px;

    > li {
        padding: 0 8px;
        display: inline-block;
        vertical-align: top;
        width: 33.33%;

        @media only screen and (max-width: 767px) {
            width: 100% !important;
        }
    }
}

.table-wrapper {
    table {
        width: 100%;
    }
}

.painting_svg_off {
    svg {
        path, [fill="#4e7d1e"] {
            fill: unset !important;
        }
    }
}

.search_reults_page {
    .categories_tabs-wrapper {
        .categories_tabs {
            //margin-bottom: 0;
        }
    }

    .categories_tabs-wrapper {
        padding-bottom: 0;
    }
}

.disable_padding_top {
    padding-top: 0 !important;
}

.disable_margin_bottom {
    margin-bottom: 0 !important;
}

.disable_margin_top {
    margin-top: 0 !important;
}

.disable_padding_bottom {
    padding-bottom: 0 !important;
}

.section_header_holder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    @media only screen and (max-width: 767px) {
        display: block;
        .carousel_nav{
            padding-top: 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}

.carousel_nav {
    @media only screen and (max-width: 767px) {
        display: flex;
    }

    button {
        display: inline-block;
        vertical-align: top;
        width: 40px;
        height: 40px;
        line-height: 10px;
        background-color: $color-gray-100;
        margin-right: 4px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.carousel_content {
    overflow: hidden;

    > ul {
        margin: 0 -8px;
        position: relative;
        white-space: nowrap;

        > li {
            padding: 0 8px;
            white-space: normal;
            width: 33.33%;
            display: inline-block;
            vertical-align: top;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }

            .box_with_desc {
                padding-bottom: 0;
                height: 173px;

                .desc {
                    h3 {
                        font-size: 14.4px;
                        line-height: 19.44px;
                        color: $color_white;
                    }

                    p {
                        overflow: hidden;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                }

                .carousel_item_style {
                    text-decoration: unset;
                }
            }
        }
    }
}

.photos-with-desc-wrapper {
    a {
        &:before {
            display: none;
        }
    }

    .item-list {
        .box_with_desc {
            p {
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.box_with_desc {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 5px;

    &:hover {
        .image {
            transform: scale(1.1);
        }
    }

    .image {
        transition: $transition;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        //background-size: cover;
        background-size: 100%;

    }

    .desc {
        padding: 11px 16px;
        position: absolute;
        left: 0px;
        //width: calc(100% - 16px);
        width: 100%;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.7);
        color: $color_white;
        font-size: 18px;
        line-height: 24.3px;
        font-weight: $unnamed-font-weight-bold;
    }
}

.aside_desc_box {
    svg {
        width: 8px;
        margin-right: 8px;
    }

    rect {
        fill: $theme-main !important;
    }

    .item {
        display: block !important;

        .heading {
            padding-bottom: 16px;
            margin-bottom: 16px;
            border-bottom: 1px solid $theme-main;
        }
    }
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.days_table {
    .td {
        &:hover {
            background-color: $color_white !important;
            border-radius: 4px !important;
            color: $color-gray-900 !important;

            &:not(.selected_day) {
                background-color: $color_white !important;
            }

            p {
                font-weight: 700 !important;
            }
        }
    }
}

.mycalendar2_datepicker .calendar_content .days_table .td.active.active:hover, .mycalendar2_datepicker .calendar_content .days_table .td.active_selected.active:hover {
    &:hover {
        border-radius: 4px !important;
        color: $color-gray-900 !important;

        background-color: $color_white !important;

        &.selected_day {
            color: $color-neutral-600 !important;
        }

        p {
            font-weight: 700 !important;

            &:before {
                background-color: #1F6AD1 !important;;
            }
        }
    }
}

.mycalendar2_datepicker .calendar_content .days_table .td.active, .mycalendar2_datepicker .calendar_content .days_table .td.active_selected {
    //border-radius: 4px !important;
    //background-color: $color-neutral-200;
    color: $color-gray-900;
    font-weight: 700;
    background-color: transparent;
    position: relative;
    z-index: 2;
    background-color: #e3edfa;
    border-radius: 6px;

    &:after {
        border-radius: 4px !important;
        background-color: $color-neutral-200;
        content: '';
        position: absolute;
        height: calc(100% - 5px);
        width: calc(100% - 5px);
        top: 2.5px;
        left: 2.5px;
        z-index: -1;
    }

    p {
        &:before {
            content: '';
            width: 4px;
            height: 4px;
            background-color: $color-neutral-600;
            display: inline-block;
            margin: 0 4px 4px 0;
            vertical-align: middle;
            border-radius: 50%;
        }
    }
}


.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            .item {
                .content {
                    .date {
                        top: 140px !important;
                    }
                }

                .preview {
                    height: 173px;

                    img {
                        background-color: $color_white;
                        height: 100% !important;
                        //max-height: none !important;
                    }
                }
            }
        }
    }
}


.error_top {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    height: 120px;
    @media only screen and (max-width: 767px) {
        height: 60px;
        margin-bottom: 30px;
        img {
            height: 30px;
            width: auto;
        }
    }

    ul {
        text-align: center;
        width: 100%;

        > li {
            display: inline-block;
            vertical-align: middle;
            margin-right: 48px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.error_page_content {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    min-height: calc(100vh - (120px * 2));
    padding: 60px 0;
    @media only screen and (max-width: 767px) {
        height: auto;
        margin-bottom: 48px;
    }

    > div {
        width: 100%;
    }
}


header {
    .bottom_header-wrapper {
        .bottom_header {
            .header_menu {
                height: 60px;
                //overflow: hidden;

                > ul {
                    > li {
                        position: relative;

                        > ul {
                            padding: 0 !important;
                            width: 230px;
                            top: 100%;
                            text-align: left;

                            > li {
                                > div {
                                    a {
                                        padding-top: 0 !important;
                                        padding-bottom: 0 !important;
                                    }
                                }
                            }

                            @media only screen and (max-width: 767px) {
                                width: 100%;
                            }

                            > li {
                                .has_more_btn {
                                    transform: rotate(-90deg) !important;
                                }

                                > ul {
                                    position: absolute;
                                    left: 100%;
                                    width: 100%;

                                    .link_content-wrapper {
                                        &:before {
                                            display: none !important;
                                        }
                                    }

                                    div > a {
                                        padding: 16px 24px;
                                    }
                                }
                            }

                            .link_content-wrapper {
                                &:after {
                                    display: none;
                                }
                            }

                            a {
                                //padding-top: 0 !important;
                                //padding-bottom: 0 !important;
                                display: block;

                            }


                        }
                    }
                }
            }
        }
    }
}


.modular_view_base {
    .block_heading-wrapper {
        &.non_modular{
            .heading{
                h2{
                    font-size: 37px;
                    line-height: 42px;
                    font-weight: 700;
                }
            }
        }
        .heading {
            h2 {
                //font-size: 37px;
                font-size: 28px;
                line-height: 42px;
                font-weight: 700;
            }
        }
    }

    //img {
    //    float: none !important;
    //}

    .downloads {
        margin-bottom: 48px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
        }
    }

    .faq_categories {
        margin-bottom: 48px;
        @media only screen and (max-width: 991px) {
            margin-bottom: 0;
        }
    }
}

.promo_banner_holder {
    margin-top: 32px;

    .overtitle {
        font-weight: 700;
        margin-bottom: 16px;
        display: block;
        font-size: 12.5px;
        line-height: 16.86px;
        color: $color-gray-600;
        text-transform: uppercase;
    }
}
.promo_banner{
    margin-bottom: 48px;
    font-size: 18px;
    line-height: 24.3px;
    .desc_side{
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -ms-flex-align: flex-start;
        -webkit-align-items:flex-start;
        -webkit-box-align: flex-start;
        gap: 32px;
        align-items: flex-start;
        img{
            margin-left: auto;
        }
        @media only screen and (max-width: 991px) {
            flex-flow: column;

        }
        > div{
            order: 1;
        }
        > img{
            order: 2;
        }
    }
    &.text_float{
        .desc_side{
           display: block;
        }
        img{
            float: right;
            margin-left: 32px;
            margin-bottom: 32px;
        }
    }
    img{
        width: 550px;
        height: auto;


        @media only screen and (max-width: 1200px) {
            width: 350px;
        }
        @media only screen and (max-width: 991px) {
            float: none;
            width: 100%;
            margin-left: 0;
        }
    }
    h3{
        font-size: 21.6px;
        line-height: 29.16px;
        color: $color-gray-900;
        font-weight: 700;
        margin-bottom: 24px;
    }
    //p,ul{
    //    margin-bottom: 32px;
    //}

    p{
        margin-bottom: 32px;
    }
    //ul{
    //    list-style: disc;
    //    padding-left: 18px;
    //    > li{
    //        margin-bottom: 15px;
    //        &:last-child{
    //            margin-bottom: 0;
    //        }
    //    }
    //}
}
//
//.promo_banner {
//    display: flex;
//    flex-flow: row;
//    flex-wrap: nowrap;
//    margin-bottom: 48px;
//    overflow: hidden;
//    img{
//        align-self: flex-start;
//    }
//    @media only screen and (max-width: 767px) {
//        display: block;
//    }
//
//    > div {
//        &.desc_side {
//            width: 46%;
//            padding-right: 32px;
//            font-size: 18px;
//            line-height: 24.3px;
//            color: $color-gray-600;
//            @media only screen and (max-width: 767px) {
//                width: 100%;
//                padding-right: 0;
//                padding-bottom: 32px;
//            }
//
//            .promo_desc {
//
//            }
//
//            .promo_title {
//                margin-bottom: 24px;
//            }
//            p{
//                margin-bottom: 32px;
//            }
//            h3 {
//                font-size: 21.6px;
//                line-height: 29.16px;
//                color: $color-gray-900;
//                font-weight: 700;
//            }
//        }
//
//        &.image_side {
//            width: 54%;
//            height: 0;
//            padding-bottom: 31%;
//            background-repeat: no-repeat;
//            background-position: center;
//            background-size: cover;
//            border-radius: 2px;
//            @media only screen and (max-width: 767px) {
//                width: 100%;
//                padding-bottom: 66%;
//                margin-right: 32px;
//            }
//        }
//    }
//}

.mobile_btn {
    line-height: 60px;
    width: 48px;
    height: 48px;
    border-radius: $border-radius-4;
    background-color: $color-gray-100;
}

.article_page-wrapper {
    @media only screen and (max-width: 767px) {
        //display: block;
        .content_side {
            //display: block;
        }
    }

    .gallery_block-wrapper {
        @media only screen and (max-width: 991px) {
            margin-bottom: 32px;
        }
    }

    .article_block-wrapper {
        @media only screen and (max-width: 991px) {
            margin-bottom: 32px;
        }

        .article_block {
            .block_content-wrapper {
                .faq {
                    img{
                        max-width: 100% !important;
                        height: auto;
                    }
                    .list {
                        .faq_item {
                            border-radius: $border-radius-4 !important;

                            overflow: hidden;

                            //.downloads_item {
                            //    background-color: $color-gray-100 !important;
                            //    width: 100% !important;
                            //    display: flex !important;
                            //    flex-flow: row;
                            //    flex-wrap: nowrap;
                            //    border-color: $color-gray-200 !important;
                            //
                            //    .addon_element {
                            //        path {
                            //            fill: $theme-icon;
                            //        }
                            //    }
                            //
                            //    svg, img {
                            //        margin-left: auto;
                            //        order: 3;
                            //    }
                            //
                            //    .file_name {
                            //        order: 1;
                            //        color: #1861C6 !important;
                            //    }
                            //
                            //    .additions {
                            //        order: 2;
                            //
                            //        span {
                            //            background-color: transparent !important;
                            //            color: #1861C6 !important;
                            //            padding: 0 !important;
                            //            font: normal normal normal 14px/19px Lato !important;
                            //        }
                            //    }
                            //}
                            //
                            //.downloads_item {
                            //    padding: 16px;
                            //}

                            &.active {
                                background-color: $color_white;

                                &:hover {
                                    .question {
                                        background-color: $color_white;
                                    }
                                }
                            }

                            .question {
                                justify-content: space-between;
                                width: 100%;
                                color: #151B28;
                                padding: 12px 16px;
                                font: normal normal bold 18px/27px Lato;
                                cursor: pointer;

                                h3 {
                                    margin: 0;
                                    font: normal normal bold 22px/32px Lato;
                                }
                            }

                            .answer {
                                position: relative;
                                padding-top: 14px;
                                //font: normal normal normal 16px/24.4px Lato;
                                h4{
                                    font-size: 18px !important;
                                    line-height: 27px !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.header_menu {
    > ul {
        > li {
            &:not(.menu_dots) {
                @media only screen and (max-width: 767px) {
                    display: block !important;
                }
            }
        }
    }
}

.menu_dots {
    @media only screen and (max-width: 767px) {
        display: none !important;
    }
}


.send_message_block-wrapper {
    .addon_element rect {
        fill: transparent !important;
    }
}


.cards {
    .item {
        .additional_info {
            padding-top: 4px;
        }

        display: block !important;

        .preview {
            margin-bottom: 21px;
            height: 151px;
            width: 100% !important;

            img {
                object-fit: cover;
                width: 100%;
                height: 100% !important;
            }
        }
    }

    .item-list {
        > li {
            display: inline-block;
            vertical-align: top;
            width: 33.33%;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
}

.events_list-wrapper {
    .list-item {
        a {
            padding: 0px;
        }
    }
}

.calendar_hidden_popup {
    @media only screen and (max-width: 991px) {
        position: relative !important;
        width: 100% !important;
        right: auto !important;
        left: auto !important;
    }
}

.institutions_block-wrapper {
    .item-list {
        .item {
            @media only screen and (max-width: 767px) {
                display: block !important;
                .preview {
                    width: 100% !important;
                    margin-right: 0 !important;
                }
                .content {
                    margin-top: 16px;
                }
            }
        }
    }
}

.flex_list {
    > ul {
        display: flex;
        flex-flow: row;
        flex-wrap: wrap;

        > li {
            > a {
                height: 100%;
            }
        }
    }
}


[data-whatintent='mouse'] *:focus, [data-whatintent='touch'] *:focus {
    outline: none !important;
}

.breadcrumbs {
    a, p {
        font-size: 14px !important;
        line-height: 1.42857;
    }
}

//
//.btn{
//    transition: 0.3s all ease;
//    &:hover{
//        transform: scale(0.98);
//    }
//}
.institutions_list-wrapper {
    .content {
        padding-top: 21px;
    }
}

.gallery_list {
    display: flex;
    flex-wrap: wrap;

    a {
        height: 100%;
        display: block !important;
    }
}

.institutions_list-wrapper, .events_list-wrapper {
    //margin-bottom: 32px;
    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0;

        > li {
            width: 33.33%;
            @media only screen and (max-width: 991px) {
                width: 50%;
            }
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }

        a {
            height: 100%;
            display: block !important;
        }
    }
}

.sitebar_off {
    .content_side {
        .item-list {
            //display: flex;
            //flex-wrap: wrap;
            margin: 0 -8px;

            li:not(.newest_info_block-wrapper *) {
                width: 25%;
                @media only screen and (max-width: 991px) {
                    width: 100% !important;
                }
                @media only screen and (max-width: 767px) {
                    width: 100% !important;
                }
            }
        }
    }
}


.sitebar_off {
    .newest_info_block-wrapper, .multimedia_block-wrapper, .institutions_block, .institutions_block-wrapper {
        .item-list {
            //display: flex;
            //flex-wrap: wrap;
            margin: 0 -8px;

            li {
                width: 25%;
            }
        }
    }
}


#content.main {
    .newest_info_block-wrapper {
        .item-list {
            //display: flex;
            //flex-wrap: wrap;
            margin: 0 -8px;

            li {
                width: 100% !important;
            }
        }
    }
}

.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            li {
                height: auto !important;
            }

            .item {
                //height: 100% !important;
                display: block !important;

                .content {
                    padding-bottom: 30px !important;
                    display: block !important;
                    height: calc(100% - 173px);

                    .heading-wrapper {
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }
}

.newest_info_block-wrapper .item-list .item .content {
    height: calc(100% - 173px);

    p {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.js_prev_button, .js_next_button {
    svg {
        path {
            fill: #b7c1cc !important;
        }
    }
}

.warnings_block{
    li.content{
        display: list-item !important;
    }
}


.newest_info_block-wrapper {
    li{
        display: list-item;
    }
    .item-list {
        ul {
            display: flex;
            flex-flow: row;
            flex-wrap: wrap;
            gap: unset !important;

            background-color: transparent !important;
            @media only screen and (max-width: 767px) {
                display: block;
            }
            margin: 0 -8px;

            > li {
                padding: 0 8px;
                width: 33.33%;
                margin-bottom: 16px;
                @media only screen and (max-width: 991px) {
                    width: 50%;
                }
                @media only screen and (max-width: 767px) {
                    width: 100%;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        a {
            height: 100%;
            display: block !important;
            width: 100%;

            > .preview {
                border-top-left-radius: $border-radius-4 !important;
                border-top-right-radius: $border-radius-4 !important;
                border-bottom-left-radius: 0 !important;
                border-bottom-right-radius: 0 !important;

                img {
                    //max-height: none !important;
                    height: 173px;
                    width: 100% !important;
                    @media only screen and (max-width: 767px) {
                        height: 100% !important;
                    }
                }
            }

            > .content {
                //height: calc(100% - 173px);
                border-bottom-left-radius: $border-radius-4;
                border-bottom-right-radius: $border-radius-4;
                border: 1px solid $color-gray-200;
                padding: 20px;
                display: block !important;

                .heading {
                    display: block;
                    margin-bottom: 16px;
                }

                .additions {
                    display: flex !important;
                    flex-flow: column;
                    gap: 12px !important;
                }
            }
        }
    }
}

.form_row12 {
    .field-box {
        margin-bottom: 16px;
    }
}

.activity_carousel {
    margin-bottom: 48px;
}

.block_annotation {
    margin-bottom: 32px;

    &.default_font {
        font-size: 18px !important;
        line-height: 27px;
    }
}

.block_quote {
    margin-top: 20px;
    margin-bottom: 20px;
}

.block_heading-wrapper {
    margin-bottom: 32px;

    .heading {
        padding-bottom: 0 !important;
    }
}

header .bottom_header-wrapper .bottom_header .header_menu ul li .link_content-wrapper.on a {
    text-decoration: underline !important;
}

.myajaxlist_paginate_box {
    margin-bottom: 64px;
    //margin-top: -16px;
}

.carousel_elements_parent {
    > li {
        &:last-child {
            @media only screen and (max-width: 767px) {
                margin-bottom: 0 !important;
            }
        }
    }
}
#content{
    &.szkola {

    }
}
#content {
    &.main, &.przedszkole, &.placowka_edukacyjna, &.glowna {
        .newest_info_block-wrapper {
            .item-list {
                > ul {
                    margin: 0;

                    > li {
                        padding-left: 0;
                        padding-right: 0;
                        width: 100% !important;
                        padding-bottom: 16px;
                        margin-bottom: 16px;
                        border-bottom: 1px solid $color-gray-200;

                        &:last-child {
                            padding-bottom: 0;
                            margin-bottom: 0;
                            border-bottom: 0;
                        }
                    }
                }

                .item {
                    width: 100%;
                    display: flex !important;
                    flex-flow: row;
                    flex-wrap: nowrap;
                    //height: 108px;
                    height: auto;
                    gap: 0;
                    @media only screen and (max-width: 767px) {
                        display: block !important;
                        height: auto;
                    }

                    > div {
                        height: 100%;
                        @media only screen and (max-width: 767px) {
                            height: auto;
                        }

                        &.content {
                            padding: 0 0 0 20px;
                            @media only screen and (max-width: 767px) {
                                padding: 20px 0 0 0;
                            }
                        }
                    }

                    .preview {
                        width: 192px;
                        @media only screen and (max-width: 767px) {
                            width: 100%;
                            height: 173px;
                        }
                    }

                    .content {
                        width: calc(100% - 192px);
                        border: 0;
                        @media only screen and (max-width: 767px) {
                            width: 100%;
                        }

                        h3 {
                            display: -webkit-box !important;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        p {
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .heading {
                            margin-bottom: 8px !important;
                        }

                        .additions {
                            display: block !important;

                            > div {
                                display: inline-block;
                                vertical-align: top;
                                margin-right: 16px;

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.selected_day {
    background-color: #1F6AD1 !important;
    color: $color-white !important;
    border-radius: 6px !important;

    &.hover {
        color: #1F6AD1 !important;
    }

    p {
        &:before {
            background-color: $color-white !important;
        }
    }
}

.datepicker.datepicker-dropdown {
    padding: 0;
    width: 350px;

    thead {
        .cw {
            &:after {
                content: 'Tydzień';
            }
        }
    }

    .old, .new {
        opacity: 0.5;
        cursor: pointer;
    }

    .dow {
        text-align: center;
    }

    .datepicker-days {
        text-align: center;

        table {
            thead {
                tr {
                    th {
                        &:first-child, &:last-child {
                            border-radius: 0;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }

            }
        }

        .datepicker-switch {
            padding-right: 30px;
            text-align: center !important;
        }

        .tfoot {
            tr {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                cursor: pointer;
            }
        }
    }
}

.datepicker table tr td:hover {
    background-color: #0029ff1f;
}

.datepicker table tr td:focus {
    background-color: #0029ff1f;
}

.datepicker table tr td {
    cursor: pointer;
}

.bootstrap-datetimepicker-widget table tr td {
    padding-right: 10px;
    padding-left: 10px;
    align-items: center;
    text-align: center;
    justify-content: center;

}

.datepicker table tr td.old {
    height: 0;
    line-height: 0;
    opacity: 0.5;
    cursor: pointer;
}

.datepicker table tr td.new {
    opacity: 0.5;
    cursor: pointer;
}

.bootstrap-datetimepicker-widget table {
    align-items: center;
    text-align: center;
    justify-content: center;
}

.bootstrap-datetimepicker-widget table thead th {
    align-items: center;
    text-align: center;
    justify-content: center;
}


.field_checkbox_handler {
    padding: 15px;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 8px;

    &:last-child {
        margin-bottom: 0;
    }

    .field_checkbox_box {
        > div.field_checkbox_input {
            padding-top: 0 !important;
        }
    }

    .field_checkbox_subtitle {
        padding-top: 0 !important;

        > div {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .field_checkbox_box {
        display: flex;
        flex-flow: row;
        flex-wrap: nowrap;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
    }

    .field_checkbox_subtitle {
        //display: flex !important;
        flex-flow: row;
        flex-wrap: nowrap;
        direction: rtl;
    }

    img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        margin-right: 10px;
    }
}

.search_reults_page {
    margin-bottom: 48px;
}

//
//.calendar_popup_element ul > li div:first-child {
//    padding: 0 !important;
//}

.block_image, .send_message_block-wrapper {
    margin-bottom: 48px;
}

.mulitmedia_gallery_list-wrapper {
    margin-bottom: 48px;
}

.szkola {
    .institutions_block-wrapper .item-list .item.no_padding {
        padding: 0;

        .posted {
            margin-bottom: 16px;
        }

        .preview {
            margin-bottom: 0 !important;
        }
    }
}

#content {
    .gallery_list {
        .item {
            padding: 0;
            border: 0;

            .preview {
                margin-bottom: 16px;
            }
        }
    }

    &.szkola, &.przedszkole, &.placowka_edukacyjna {
        .gallery_list {
            .item {
                .preview {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    margin-bottom: 0;
                }

                .content {
                    border: 1px solid $color-gray-200;
                    border-top: 0;
                    padding: 16px;
                }
            }
        }
    }

    &.placowka_edukacyjna {
        .gallery_list {
            .item {
                .preview {
                    border-radius: 0;
                }
            }
        }
    }
}

.see_all {
    display: flex !important;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    > a, > span {
        display: inline-block;
        vertical-align: middle;
    }

    span {
        padding-left: 16px;
    }
}

.login_box {
    background-color: $color-gray-100;
    padding: 50px;
    width: 50%;
    margin: 0 auto 48px auto;
    border: 1px solid $color-neutral-200;
    box-shadow: inset 0px 1px 0px #E9EEF7, 0px 2px 4px #3A435714;
    @media only screen and (max-width: 1200px) {
        width: 80%;
    }
    @media only screen and (max-width: 991px) {
        width: 100%;
        padding: 30px;
    }

    .block_description {
        margin-bottom: 32px;

        &.default_font {
            color: $color-gray-900;
        }
    }

    .default_form {
        span {
            display: inline-block;
            background-color: $color_err;
            color: $color_white !important;
            font: normal normal normal 13px/19px Lato;
            padding: 5px 10px;
            position: relative;
            margin-top: 10px;

            &:before {
                background-color: $color_err;
                position: absolute;
                left: 5px;
                bottom: calc(100% - 1px);
                content: '';
                width: 10px;
                height: 5px;
                clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
            }
        }

        > div {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }

            &:last-child {
                padding-top: 16px;
            }
        }

        input[type="submit"] {
            align-items: center;
            justify-items: center;
            background-color: $theme-cta;
            color: $theme-cta-font;
            height: calc(100% - 8px);
            padding: 0px 24px;
            margin-right: 4px;
            width: fit-content;
            transition: $transition;
            border: unset;
            border-radius: $border-radius-4;
            font: normal normal bold 14px/19px Lato;
            line-height: 48px;

            &:hover {
                text-decoration: underline;
            }

            @media only screen and (max-width: 767px) {
                margin: 16px 0;
                width: 100%;
                height: 48px;
                padding: 0 24px;
            }
            //&:hover{
            //    filter: brightness(120%) saturate(120%);
            //}
        }
    }
}


.err_txt {
    display: inline-block;
    background-color: $color_err;
    color: $color_white !important;
    font: normal normal normal 13px/19px Lato;
    padding: 5px 10px;
    position: relative;

    &:before {
        background-color: $color_err;
        position: absolute;
        left: 5px;
        bottom: calc(100% - 1px);
        content: '';
        width: 10px;
        height: 5px;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
}

.main_banner-wrapper, .warnings_block-wrapper  {
    &.margin_bottom {
        margin-bottom: 48px;
    }
}

.institutions_list-wrapper {
    &.show_list {
        &.flex_list {
            .item-list {
                flex-flow: column !important;
            }
        }

        .item {
            .preview {
                display: none;
            }

            .content {
                padding-top: 0;
                display: block;

                .heading {
                    margin-bottom: 14px;
                }
            }
        }

        > ul {
            > li {
                width: 100% !important;
                margin-bottom: 16px !important;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &.show_photo_on_list {
            .item {
                display: flex !important;
                flex-flow: row;
                flex-wrap: nowrap;
                @media only screen and (max-width: 767px) {
                    display: block !important;
                }

                .preview {
                    display: block;
                    width: 268px;
                }

                > .content {
                    width: calc(100% - 268px);
                    @media only screen and (max-width: 767px) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.table_scroll {
    //@media only screen and (max-width: 767px) {
    //
    //}
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
        height: 4px;
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
        background: $theme-main;
    }
    table {
        min-width: 100% !important;
        width: auto !important;
    }
}

.answer {
    ul {
        //padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 18px;
        list-style: disc;

        &:last-child {
            padding-bottom: 0;
        }

        > li {
            //margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.sitemap_list {
    &.default_font {
        a {
            color: $color-gray-900 !important;
        }
    }
}

.article_block {
    video {
        object-fit: cover;
    }
}

.placowka_edukacyjna {
    .has_more_btn {
        @media only screen and (max-width: 767px) {

            img {
                display: none !important;
            }
        }

    }
}

.has_more_btn {
    @media only screen and (max-width: 767px) {
        background-repeat: no-repeat !important;
        background-position: center !important;
        background-image: none !important;
        //img{
        //    display: none !important;
        //}
    }

}

.main_banner-wrapper .carousel_elements_parent-wrapper .main_banner .content_block-wrapper {
    @media only screen and (max-width: 767px) {
        background-color: transparent !important;
        backdrop-filter: none;
    }
}

.main_banner-wrapper {
    .carousel_elements_parent-wrapper {
        .main_banner {
            .image-wrapper {
                @media only screen and (max-width: 767px) {
                    background-color: $theme-dark !important;
                }

                img {
                    @media only screen and (max-width: 767px) {
                        position: absolute !important;
                        top: 0 !important;
                        left: 0 !important;
                        height: 100% !important;
                        width: 100% !important;
                        object-fit: cover !important;
                        opacity: 0.5;
                    }
                }
            }
        }
    }
}

.form_row6 {
    @media only screen and (max-width: 767px) {
        margin-bottom: 16px;
    }
}

.datails {
    svg {
        height: 20px;
        width: 20px;
    }
}

.dashboard_step_form {
    .table-title {
        margin-bottom: 20px;
    }
}

.myajaxlist_paginate_box > div {
    color: #000000;
}

.declaration {
    margin-bottom: 48px;

    .default_font {
        font: normal normal normal 18px/27px Lato;
    }
}

.warnings_block {
    p, li {
        margin-bottom: 10px;
    }

    //ul {
    //    padding-left: 18px;
    //    list-style: disc;
    //
    //    ul {
    //        padding-top: 16px;
    //    }
    //
    //    li {
    //        &:last-child {
    //            padding-bottom: 0;
    //        }
    //
    //        &::marker {
    //            color: $color-gray-900;
    //        }
    //    }
    //}

    //ul, ol {
    //    margin-left: 0;
    //    padding-left: 18px;
    //    padding-bottom: 0 !important;
    //
    //    ul, ol {
    //        margin-left: 0 !important;
    //        padding-bottom: 0;
    //        padding-left: 18px;
    //    }
    //}
}

.block_description {
    &.default_font {
        table, .warnings_block {
            margin-bottom: 48px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        > p{
            > img{
                margin-bottom: 48px;
            }
        }
        > img{
            margin-bottom: 48px;
        }
    }
}

.lb-outerContainer {
    @media only screen and (max-width: 767px) {
        width: calc(100% - 30px) !important;
        margin-left: 15px !important;
        height: auto !important;
        .lb-image {
            width: 100% !important;
            height: auto;
        }
    }
}


.mobile_hide{
    @media only screen and (max-width: 767px) {
        display: none;
    }
}
.mobile_show{
    display: none;
    @media only screen and (max-width: 767px) {
        display: block !important;
    }
}

.sidebar_list{
    .image{
        background-size: cover;
    }
}

.downloads_item{
    text-decoration: none !important;
}

.sidebar{
    .item-list{
        a{
            border-radius: 6px;
            overflow: hidden;
            > img{
                transition: 0.3s all ease;
                @media only screen and (max-width: 991px) {
                    display: none;
                }
            }
            &:hover{
                > img{
                    transform: scale(1.1);
                }

            }
        }
    }
}

.sidebar_img_mobile{
    @media only screen and (max-width: 991px) {
        display: block !important;
    }
}

figure.image{
    display: inline-block;
    text-align: center;
    figcaption{
        padding-top: 10px;
        font-size: 14px;
    }
}
//.myajaxlist_paginate_box>div.pages_box .one_page.active{
//    background-color: $theme-cta-600 !important;
//}
.side_bar_left{
    @media only screen and (max-width: 991px) {
        .img_desctop_holder{
            display: none;
        }
        .item-list{
            text-align: center;
        }
        display: block;
        flex-flow: row;
        flex-wrap: wrap;
        gap: 16px;
        > div{
            width: 100%;
        }
    }
    @media only screen and (max-width: 767px) {
        display: block;
        > div{
            width: auto;
        }
    }
}

#footer{
    a{
        text-decoration: underline;
        &:hover{
            text-decoration: none;
        }
    }
}

.faq_categories{
    .faq_categories_list{
        display: block !important;
        .faq_category_block{
            display: block !important;
        }
    }
}

.answer {
    &.default_font{
        h4{
            font-size: 18px !important;
            line-height: 27px !important;
        }
    }
}

.block_quote{
    .addon_element{
        width: auto !important;
    }
}
//
//.events_list-wrapper{
//    .item {
//        .category {
//            text-decoration: none;
//        }
//    }
//}

.item {
    .category {
        text-transform: none;
    }
}
 .faq .list .faq_item .question button{
     @media only screen and (max-width: 767px) {
         font: normal normal bold 22px/32px Lato !important
     }
 }
.article_block, .promo_banner{
    ul,ol{
        color: $color-gray-900;
    }
    ul{
        padding-left: 18px;
        list-style: disc;
        li{
            &::marker{
                color: $color-gray-900;
            }
        }
    }
}
 .warnings_block, .article_block, .promo_banner {
     ul,ol{
         font-size: 18px;
         line-height: 27px;
         > li{
             > ul, > ol{
                 margin-top: 0;
                 padding-top: 13px;
             }
         }
     }
     ul{
         padding-left: 18px;
         list-style: disc;
         //> li{
         //    > ul {
         //        padding-top: 13px;
         //    }
         //}
         li{
             &:last-child{
                 padding-bottom: 0;
             }
             //&::marker{
             //    color: $color-gray-900;
             //}
         }
     }
     ul, ol {
         margin-left: 0;
         padding-left: 18px;
         padding-bottom: 0 !important;
         li{
             margin-bottom: 13px;
         }
         //color: $color-gray-900;
         //font-size: 18px;
         //line-height: 27px;
         ul, ol{
             margin-left: 0 !important;
             padding-bottom: 0;
             padding-left: 18px;
         }
     }
 }

 .list{
     > ul{
         padding-left: 0 !important;
         list-style: none !important;
     }
 }

 .downloads_item{
     > svg{
         flex-shrink: 0;
     }
     .additions{
         white-space: nowrap;
         margin-left: auto;
     }
 }


.presentations{
    height: 416px;
    width: 100%;
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    .nav-wrapper {

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 0 40px 20px 40px;
        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;

        @media only screen and (max-width: 767px) {
            padding: 0 20px 20px 20px;
        }
        .dots {
            display: flex;
            grid-template-columns: repeat(auto-fit, 12px);
            gap: 8px;

            button {
                height: 12px;
                width: 12px;
                border-radius: 50%;
                background-color: transparent;
                border: 2px solid $color-white;
                transition: $transition;

                &.on {
                    background-color: $color-white;
                }

                .slide_number {
                    display: none;
                }
            }
        }

        .btns {
            > div, button {
                display: inline-block;
                vertical-align: top;
                margin-right: 4px;
                width: 40px;
                height: 40px;
                line-height: 10px;
                transition: $transition;
                background-color: $color_white;
                border-radius: 4px;
                &.carousel_arrow_right {
                    margin-right: 0;
                }
            }
        }
    }
    > ul{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        > li{
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: none;
            &:first-child{
                display: block;
            }
        }
    }
}

.presentation{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    > div{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $color_black;
        &.presentation_bg{
            .cover{
                z-index: 2;
                background-color: rgba(0,0,0,0.3);
            }
            > img, .cover{
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
            img{
                max-height: none !important;
                height: 100% !important;
            }
        }
        &.presentation_content{
            background-color: $color_black;

            padding: 40px;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;

            -ms-flex-align: flex-end;
            -webkit-align-items: flex-end;;
            -webkit-box-align: flex-end;;

            align-items: flex-end;;
            @media only screen and (max-width: 767px) {
                padding: 20px;
            }
            h2{
                position: relative;
                z-index: 2;
                margin-bottom: 40px;
                color: $color-white;
                font: normal normal 700 37px/50px Lato;
                @media only screen and (max-width: 767px) {
                    font: normal normal 700 24px/37px Lato;
                }
            }
        }
    }
}

.item-list {
    .content{
        .content{
            a{
                text-decoration: underline;
                &:before{
                    display: none !important;
                }
                &:hover{
                    text-decoration: none;
                }
            }
            p,ul,ol,a{
                margin-bottom: 16px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            ul,ol{
                padding-left: 13px;
            }
            ul{
                list-style: disc;
            }
            a {
                color:#4A5469;
                &:hover{
                    text-decoration: underline !important;
                }
            }
        }
    }

}

.shortcuts-wrapper{
    .item{
        .image-wrapper{
            svg{
                width: 48px;
                height: 48px;
            }
        }
    }
}

.block_with_sidebar-wrapper .sidebar-wrapper .sidebar_list .shortcuts-wrapper .item-list .item .content{
    text-align: left;
}

.annotation{
    ol{
        padding-left: 13px;
    }
   > ul{
       padding-left: 13px;
        list-style: disc;
    }
}
#footer{
    ol{
        padding-left: 13px;
    }
    ul{
        padding-left: 13px;
        list-style: disc;
    }
}
