@import "main/variables_szkola";
@import "main/importer";

@import "main/bootstrap";

.default_font { @import "main/default_font"; }
.default_form { @import "main/default_form"; }

@import "../js/plugins/select2/select2";
@import "../js/plugins/jquery_mCustomScrollbar/jquery.mCustomScrollbar.scss";

@import "main/style";

div.corner-left-bottom rect, div.corner-top-right rect {
    fill: $theme-main;
}

div.heading rect, div.category rect {
    fill: $theme-main;
}

.addon_element [fill="#4e7d1e"] {
    fill: unset;
}


.bottom_header-wrapper{
    background-color: $color-gray-100;
    text-align: center;

}
.menu_dots{
    padding: 0 24px;
}
.link_content-wrapper{
    padding:  0 24px;
    position: relative;
    a{
        text-transform: uppercase;
        padding: 23px 0 !important;
    }
    &:after{
        content: '';
        border-right: 1px solid $theme-main;
        position: absolute;
        right: 0;
        top: 17px;
        height: 32px;
    }
}
header .bottom_header-wrapper .bottom_header .header_menu > ul > li > .link_content-wrapper.on a{
    color: #151B28 !important;
    text-decoration: underline;
}
header {
    .bottom_header-wrapper {
        .bottom_header {
            .header_menu {
              > ul {
                > li {

                    vertical-align: middle;
                    margin-left: 0 !important;
                    @media only screen and (max-width: 767px) {
                        ul{
                            background-color: transparent !important;
                            li{
                                background-color: transparent !important;
                            }
                        }
                        &.menu_dots{
                            display: none !important;
                        }
                        width: 100% !important;
                        text-align: left;
                        .link_content-wrapper{
                            padding: 0;
                            &:after{
                                display: none;
                            }
                        }
                    }
                    &:last-child{
                        .link_content-wrapper{
                            &:after{
                                display: none;
                            }
                        }
                    }
                }
              }
            }
        }
    }
}
.btn{
    border-radius: $border-radius-4;

    line-height: 44px !important;
    &:after{
        border: 2px solid $theme-main !important;
    }
    &:hover{
        text-decoration: underline;
        filter: none !important;
        //background-color: $opacity-main !important;
        //border-color-color: $opacity-main !important;
        &:after{
            filter: none !important;
        }
    }
}
header .bottom_header-wrapper .bottom_header .header_menu > ul > li > .link_content-wrapper a:hover{
    color: inherit !important;
    text-decoration: underline;
}
nav.menu.header_menu {
    ul {
        li:not(.menu_dots):hover {
            background-color: rgba(128, 128, 128, 0.21);
            color: black;

            span {
                color: black;
            }
        }
    }
}
.main_banner-wrapper {
    border-radius: $border-radius-4;
    overflow: hidden;
    .carousel_elements_parent-wrapper {
        .nav-wrapper {
            right: 0;
            left: auto;
            .dots {
                button{
                   border: 2px solid  $color-gray-500;
                    &.on{
                        border-color: $color_white
                    }
                }
            }
            .btns {
                button{
                    path{
                        fill: $color_white;
                    }
                    background-color: $color-gray-700;
                }
            }
        }
    }
}
.slide_holder{
    .corner-left-bottom, .corner-top-right{
        display: none;
    }
    .content_block-wrapper{
        order: 2;
    }
    .image-wrapper{
        order: 1;
    }
}
.gallery_list{
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    > li{
        padding: 0 8px;
        width: 33.33%;
    }
    a{
        height: 100%;
        width: 100%;
        display: block !important;
        margin-bottom: 16px;
        border: 0 !important;
        > .preview{
            border-top-left-radius: $border-radius-4 !important;
            border-top-right-radius: $border-radius-4 !important;
            border-bottom-left-radius:0 !important;
            border-bottom-right-radius: 0 !important;
            img{
                max-height: none !important;
                height: 173px;
                width: 100% !important;
            }
        }
        > .content{
            height: calc(100% - 173px);
            border-bottom-left-radius: $border-radius-4;
            border-bottom-right-radius: $border-radius-4;
            border: 1px solid $color-gray-200;
            padding: 20px;
            display: block !important;
            .heading{
                display: block;
                margin-bottom: 16px;
            }
            .additions{
                display: flex !important;
                flex-flow: column;
                gap: 12px !important;
            }
        }
    }
}
.newest_info_block-wrapper{
    .item-list{
        //display: flex;
        flex-flow: row;
        flex-wrap: wrap;
        gap: unset !important;
        margin: 0 -8px;
        background-color: transparent !important;
        @media only screen and (max-width: 767px) {
            display: block;
        }
        a{
            //padding: 0 8px;
            //width: 33.33%;
            //display: block !important;
            //margin-bottom: 16px;
            //@media only screen and (max-width: 991px) {
            //    width: 50%;
            //}
            //@media only screen and (max-width: 767px) {
            //    width: 100%;
            //}
           > .preview{
               border-top-left-radius: $border-radius-4 !important;
               border-top-right-radius: $border-radius-4 !important;
               border-bottom-left-radius:0 !important;
               border-bottom-right-radius: 0 !important;
                img{
                    //max-height: none !important;
                    height: 173px;
                    width: 100% !important;
                }
            }
           > .content{
               //height: calc(100% - 173px);
               border-bottom-left-radius: $border-radius-4;
               border-bottom-right-radius: $border-radius-4;
               border: 1px solid $color-gray-200;
                padding: 20px;
               display: block !important;
               .heading{
                   display: block;
                   margin-bottom: 16px;
               }
               .additions{
                   display: flex !important;
                   flex-flow: column;
                   gap: 0 !important;
               }
            }
        }
    }
}

.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            .item {
                display: block !important;
               .preview {
                   border-top-left-radius: $border-radius-4 !important;
                   border-top-right-radius: $border-radius-4 !important;
                   border-bottom-left-radius:0 !important;
                   border-bottom-right-radius: 0 !important;

                   text-align: center;
                   img {
                       height: 173px;
                       max-height: none !important;
                   }
               }
                .content {
                    background-color: $color_white;
                    border-bottom-left-radius: $border-radius-4;
                    border-bottom-right-radius: $border-radius-4;
                    border: 1px solid $color-gray-200;
                }
            }
        }
    }
}

.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            .item {
                .content {
                    .date {
                        border-radius: $border-radius-8 !important;
                        top: 140px !important;
                        border-color: $color-gray-100 !important;
                        background-color: $color-gray-100 !important;
                    }
                }
            }
        }
    }
}

.gray_block {
    background-color: $color-white;
}

.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            .item {
                //height: auto !important;
                margin-top: 0 !important;
            }
        }
    }
}
.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .calendar-wrapper {
                margin: 16px 0;
                .mycalendar2_datepicker {
                    .base_bg_color {
                        .months_change {
                            border-color: $color-gray-200 !important;;
                        }
                    }
                }
            }
        }
    }
}
.aside_desc_box {
    color:$color-gray-600;
    a{
        text-decoration: underline !important;
        &:hover{
            text-decoration: none !important;
        }
    }
    p{
        margin-bottom: 16px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .item {
        .heading {
            border-color: $color-gray-200 !important;;
        }
    }
}
.calendar-wrapper{
    border: 1px solid $color-gray-200 !important;
}
.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .shortcuts-wrapper {
                .item-list {
                    .item{
                        border: 1px solid $color-gray-200 !important;
                    }
                    &:not(.aside_desc_box){
                        .item{
                            overflow: hidden;
                            display: flex;
                            &:before{
                                position: absolute;
                                content: '';
                                width: 6px;
                                top: 16px;
                                left: -3px;
                                border-radius: 3px;
                                height: calc(100% - 32px);
                                background-color: $theme-main;;
                            }
                        }
                    }
                }
            }
        }
    }
}


.multimedia_block-wrapper {
    .multimedia_block {
        .item-list {
            .item {
                display: block;
                .preview{
                    border-radius: $border-radius-4 $border-radius-4 0 0 !important;
                    margin-bottom: 0;
                }
                > .content {
                    border-bottom-left-radius: $border-radius-4;
                    border-bottom-right-radius: $border-radius-4;
                    padding: 20px;
                    background-color: $color_white !important;
                }
            }
        }
    }
}

.carousel_nav{
    > button{
        border-radius:  $border-radius-4;;
    }
}

.activity_carousel{
    .box_with_desc{
        overflow: hidden;
        border-radius:  $border-radius-4;
        .desc{
            border-radius:  $border-radius-4;
            color: $color-gray-900;
        }
    }
}

.dark_block{
    background-color: $theme-cta-font !important;
    .addon_element{
        path, rect{
            fill: $color-white !important;
        }
    }
}

footer{
    background-color: transparent;
    .contacts {
        svg {
            path{
                fill: $color-gray-300!important;
            }
        }
    }
    > .container{
        ul {
            a{
                color:  $color-gray-900!important;
            }
        }
    }
    > .content-wrapper{
        background-color: $theme-cta-font;

        ul {
            a{
                color: $color-gray-300!important;
            }
        }
    }

    .location{
        span{
            color: $color-gray-300!important;
        }
    }
    .menu_list-wrapper {
        .menu_list {
            a {
                color: $color-gray-300!important;
            }
        }
    }
    .content-wrapper {
        .parts-list {
            .part {
                .annotation {
                    color: $color-gray-300!important;
                }
                .heading {
                    color: $color_white !important;
                }
                .contacts {
                    a {
                        color: $color-gray-300 !important;
                    }
                }

            }
        }
        .parts-list {
            .part {
                .heading{
                    &:after{
                        background-color: $theme-main !important;
                    }
                }
            }
        }
    }
    .footer_bottom{
        color: $color-gray-900 !important;
        //border-color: $color-gray-200 !important;
   border: none !important;
    }
}

.addon_element{
    path, rect{

    }
}

.calendar_heading{
    rect{

    }
}

.sidebar-wrapper, .schools_block, .location, .mail, .phone{
    path{

    }
    rect{

    }
}
.sidebar-wrapper{
    .addon_element{
        path{

        }
        rect{

        }
    }

}
.contacts{
    svg{
        path{

        }
    }
}
.send_message_block-wrapper {
    .send_message_block {
        .block_content {
            form {
                input[type="submit"]{
                    border-radius: $border-radius-4;
                    border: 2px solid $theme-main !important;
                    color: $theme-cta-font;
                    &:hover{
                        filter: none !important;
                        //background-color: $opacity-main !important;
                    }
                }
            }
        }
    }
}
.save_form_btn_holder{
    input[type="submit"] {
        border-radius: $border-radius-4;
        border: 2px solid $theme-main !important;
        color: $theme-cta-font;
        //background-color: transparent !important;
        font-size: 14.4px;
        padding: 0 24px;
        height: 48px;
        box-shadow: none;
        font-weight: 700;
        text-decoration: none;
        line-height: unset !important;
        background-color: $color_white;
        &:hover{
            filter: none !important;
            //background-color: $opacity-main !important;
        }
    }
}
.newsletter_block-wrapper {
    .addon_element {
        rect {

        }
    }
    .newsletter_block {
        form {
            .form_group {
                input[type="submit"] {
                    border-radius: $border-radius-4;
                    border: 2px solid $theme-main !important;
                    color: $theme-cta-font;
                    &:hover{
                        text-decoration: underline;
                        filter: none !important;

                    }
                }
            }
        }
    }
}

.categories_tabs-wrapper {
    .categories_tabs {
        .categories_tabs_list {
            .categories_tab {
                background-color: transparent !important;
                border-radius: $border-radius-4 !important;
                border:2px solid $color-gray-200 !important;
                color: $theme-cta-font !important;
                &:hover, &.active{
                    border-color: $theme-main !important;
                }
            }
        }
    }
}
//
//header {
//    .bottom_header-wrapper {
//        .bottom_header {
//            .header_menu {
//             > ul {
//                > li {
//                    position: relative;
//                    > ul {
//                        width: 230px;
//                        top: 100%;
//                        text-align: left;
//                        > li{
//                            position: static;
//                            .has_more_btn{
//                                transform: rotate(-90deg) !important;
//                            }
//                            > ul{
//                                position: absolute;
//                                left: 100%;
//                                width: 100%;
//                                height: 100%;
//                                .link_content-wrapper{
//                                    &:before{
//                                        display: none !important;
//                                    }
//                                }
//                            }
//                        }
//
//                        .link_content-wrapper{
//                            &:after{
//                                display: none;
//                            }
//                        }
//                        a{
//                            padding-top: 0 !important;
//                            padding-bottom: 0 !important;
//
//                        }
//                    }
//                }
//             }
//            }
//        }
//    }
//}


//.dashboard{
//    background-color: $color-gray-100;
//    padding: 40px;
//    border-radius: $border-radius-4;
//    .dash_form_holder{
//        .row{
//            margin-bottom: 24px;
//            &:last-child{
//                margin-bottom: 0;
//            }
//        }
//    }
//}

//.addon_element path{
//    fill: $theme-icon !important;
//}

.btn1{
    border-radius: $border-radius-4 !important;
    &:hover{
        filter: none !important;
        background-color: var(--opacity-main) !important;
    }
}

header {
    .bottom_header-wrapper {
        .bottom_header {
            .header_menu {
                > ul{
                    > li{
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

.siblings_events_block-wrapper .events_list-wrapper .item-list .item{
    background-color: transparent !important;
}

.menu_dots{
    line-height: 65px !important;
    &:hover,{
        background-color: rgba(128, 128, 128, 0.21);
    }
}

.activity_carousel {
    .box_with_desc {
        .desc {
            //background-color: $color_white;
            //color: $color-black;
            h3{
                font-weight: 600;
                //color: #000000 !important;
            }
        }
    }
}

.szkola {
    // Efekt przybliżenia zdjęcia
    .events_list-wrapper, .shortcuts-wrapper, .institutions_list-wrapper, .gallery_item, .newest_info_block-wrapper, .multimedia_block-wrapperr, .gallery_list {
        //.item {
        //    transition: 0.3s transform ease;
        //
        //    &:hover {
        //        transform: scale(0.95);
        //    }
        //}
        //
        //.preview {
        //    transition: 0.3s transform ease; /* Dodaj przejście dla .preview na poziomie wyższego selektora */
        //}
        .item {
            transition: 0.3s transform ease;

            &:hover {
                .preview {
                    transform: scale(0.95);
                }
            }
        }

        .preview {
            transition: 0.3s transform ease; /* Dodaj przejście dla .preview na poziomie wyższego selektora */
        }
    }

    // Efekt obramowania
    .schools_block {
        .item-list {
            a.item {
                //display: inline-block;
                position: relative;
                overflow: hidden; /* Ukryj część obramowania, która może wystawać */
                transition: 0.7s transform, 0.7s border, 0.7s border-radius;

                &:hover {
                    background-color: var(--opacity-main);
                }
            }
        }
    }

    // Efekt obramowania
    .sidebar {
        .item-list {
            a {
                .item {
                    display: inline-block;
                    position: relative;
                    overflow: hidden; /* Ukryj część obramowania, która może wystawać */
                    transition: 0.7s transform, 0.7s border, 0.7s border-radius;

                    &:hover {
                        background-color: var(--opacity-main) !important;
                    }
                }
            }
        }
    }

}

.sidebar{
    .item-list {
        a {
            //display: inline-block;
            position: relative;
            overflow: hidden; /* Ukryj część obramowania, która może wystawać */
            transition: 0.7s transform, 0.7s border, 0.7s border-radius;

            &:hover {
                //&::before {
                //    content: '';
                //    position: absolute;
                //    top: 50%;
                //    left: 50%;
                //    transform: translate(-50%, -50%);
                //    width: 100%;
                //    height: 100%;
                //    //border: dashed 2px var(--theme-main);
                //    border: 2px dashed $theme-main !important;
                //    border-radius: 5px;
                //    z-index: 3;
                //}
            }
        }
    }
}

.schools_block-wrapper .schools_block .find_school form input[type="submit"]{
    border-color: $theme-main !important;
}

.login_box{
    border-radius: 6px;
}
.main_banner-wrapper .carousel_elements_parent-wrapper .main_banner .image-wrapper img{

    margin-right: -1px;
}

.mycalendar2_datepicker .calendar_content .days_table .td.active, .mycalendar2_datepicker .calendar_content .days_table .td.active_selected{
    border: 1px solid $color-gray-100;
}

.search_results_block-wrapper .form-wrapper form .form_group input[type="submit"]{
    color: $theme-cta-font;
    border: 2px solid $theme-main !important;;
}

header .bottom_header-wrapper .bottom_header .header_menu > ul > li > ul > li > ul{
    @media only screen and (max-width: 767px) {
        padding-left: 48px;
    }
}

.header_menu{
    .has_more_btn{
        line-height: 0 !important;
    }
    svg{
        path{
            fill: #000000 !important;
        }
    }
}

.calendar-wrapper{
    margin: 0 !important;
}
.szkola{
    .myajaxlist_paginate_box > div.pages_box .one_page.active{
        //background-color: transparent !important;
        //border: 1px solid $theme-main;
        background-color: transparent !important;
        border: 1px solid $theme-cta !important;
        color: #000000 !important;
    }
}
//
//.gray_block{
//    padding-bottom: 0 !important;
//    margin-bottom: 0 !important;
//}
