@import "main/variables";

$form-border-radius: 5px;

input[type="text"], input[type="password"], textarea {
    height: 48px;
    width: 100%;
    resize: none;
    color: $color-gray-900;
    border: 1px solid $color-gray-300;
    box-shadow: 0px 1px 1px rgba(58, 67, 87, 0.04);
    padding: 0 16px;
    &::-webkit-input-placeholder { /* Edge */
        color: $color-gray-500;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $color-gray-500;
    }

    &::placeholder {
        color: $color-gray-500;
    }
}
textarea{
    height: 144px;
    padding: 16px;
}
.myform2_tags {
    .multi_list {
        margin:0;
        padding:0;
        list-style-type:none;
        li {
            display:inline-block;
            vertical-align: top;
            border:1px solid $border-color;
            padding:3px 5px;
            border-radius: 3px;
            margin-top:4px;
            margin-right: 4px;

            span {
                position:relative;
                top:3px;
            }
        }
    }
}

.field_checkbox {
    .field_checkbox_box {

        > div {
            display:inline-block;
            vertical-align: top;

            &.field_checkbox_input {
                padding:13px 10px 0 0;
            }
        }

        .checkbox_image_box {
            cursor:pointer;

            > div {
                display:none;

                width:16px;
                height:16px;
                border:2px solid $color-gray-400;
                background-color: $color_white;
                border-radius: $border-radius-4;

                &.yes {
                    background-color: $color-gray-400;
                    box-shadow: inset 0px 0px 0px 2px $color_white
                }

                &.on {
                    display:block;
                }
            }
        }

        .field_checkbox_subtitle {
            padding-top:10px;
        }
    }
}

.field_radio {
    .field_radio_box {
        > div {
            display:inline-block;
            vertical-align: top;

            &.field_radio_input {
                padding:13px 10px 0 0;
            }
        }
        .radio_image_box {
            cursor:pointer;

            width:16px;
            height:16px;
            border:2px solid $color-gray-400;
            overflow: hidden;
            position:relative;
            background-color: $color_white;
            border-radius: 50%;

            > div {
                display:none;
                background-color: $color-gray-400;
                box-shadow: inset 0px 0px 0px 2px $color_white;
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
                border-radius: 50%;
                &.on {
                    display:block;
                }
            }
        }

        .field_radio_subtitle {
            padding-top:10px;
        }
    }
}

.myform2_dropzone_box {
    padding:0 20px;
    border:1px solid #e3d4c0;

    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;

    .dropzone_box {
        padding:20px 0;
        cursor: pointer;
        color: #ADAAA2;
        background: url('/img/dropzone_ico.png') right center no-repeat;
        background-size: 30px auto;
    }

    .upload_progres_bars {
        width:100%;

        > div {
            width:100%;

            &:last-child {
                padding-bottom:10px;
            }

            > div {
                display:inline-block;

                &.dz-filename {
                    width: calc(100% - 100px);
                }

                &.dz-size {
                    width: 96px;
                }

                &.dz-progress {
                    width: 100%;
                    height: 10px;
                    background-color: #e3dedb;
                    position: relative;

                    -webkit-border-radius: 3px;
                    -moz-border-radius: 3px;
                    border-radius: 3px;

                    .dz-upload {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        background-color: #e3d4c0;

                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        border-radius: 3px;
                    }
                }
            }
        }
    }

    .previewsContainer {
        .row {
            margin-left:0;
            margin-right: 0;
            padding-bottom:10px;

            &:first-child {
                padding-top:10px;
            }

            > div {
                display:inline-block;

                &.lp {
                    display:none;
                }

                &.thumb {
                    width:70px;

                    img {
                        width:50px;
                        -webkit-border-radius: 3px;
                        -moz-border-radius: 3px;
                        border-radius: 3px;
                    }
                }

                &.name {
                    width: calc(100% - 120px);
                }

                &.action {
                    width:50px;
                    text-align: right;

                    .download {
                        display:none;
                    }

                    .delete {
                        cursor: pointer;
                        position:relative;
                        top:2px;

                        &:hover {
                            opacity:0.7;
                        }
                    }
                }
            }
        }
    }
}

