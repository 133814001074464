//$font-family: 'Lato', sans-serif;
$font-family: 'Lato';

// Adobe color scheme
$color-gray-50: #F9FBFD;
$color-gray-100: #F4F7FB;
$color-gray-200: #E9EEF7;
$color-gray-300: #D0D5E0;
$color-gray-400: #A0A7B7;
$color-gray-500: #66718B;
$color-gray-600: #4A5469;
$color-gray-700: #3A4357;
$color-gray-800: #222A3B;
$color-gray-900: #151B28;
$color-neutral-200: #D6E4F7;
$color-neutral-50: #EDF4FE;
//$theme-cta: #4E7D1E;
$theme-cta-600: #3A6211;
$theme-dark: #222A3B;
$theme-light-dark: #2D3D5E;
$theme-main: #79B937;
$theme-dark-green: #213E02;
$color-neutral-600: #1F6AD1;
$color-neutral-900: #03185F;
$color-white: #FFFFFF;
$color-black: #00000000;


// Custom colors
$color1: #000000;
$color111: green;
$color2: #53abff;
$color3: #ffce14;
$color4: pink;

/* Font/text values */
$unnamed-font-family-lato: Lato;
$unnamed-font-style-normal: normal;
$unnamed-font-weight-900: 900;
$unnamed-font-weight-bold: bold;
$unnamed-font-weight-normal: normal;
$unnamed-font-size-18: 18px;
$unnamed-font-size-26: 26px;
$unnamed-font-size-10: 10px;
$unnamed-font-size-22: 28px;
$unnamed-font-size-37: 37px;
$unnamed-font-size-45: 45px;
$unnamed-font-size-31: 31px;
$unnamed-font-size-12-5: 12.5px;
$unnamed-font-size-14: 14px;
$unnamed-font-size-14-4: 14.4px;
$unnamed-character-spacing-0: 0px;
$unnamed-character-spacing--0-29: -0.29px;
$unnamed-character-spacing--0-4: -0.4px;
$unnamed-character-spacing-0-49: 0.49px;
$unnamed-character-spacing--0-49: -0.49px;
$unnamed-character-spacing--0-5: -0.5px;
$unnamed-character-spacing-1-25: 1.25px;
$unnamed-character-spacing--0-14: -0.14px;
$unnamed-character-spacing-0-26: 0.26px;
$unnamed-line-spacing-27: 27px;
$unnamed-line-spacing-24-299999237060547: 24.299999237060547px;
$unnamed-line-spacing-34-9900016784668: 34.9900016784668px;
$unnamed-line-spacing-14-069999694824219: 14.069999694824219px;
$unnamed-line-spacing-29-15999984741211: 29.15999984741211px;
$unnamed-line-spacing-50-380001068115234: 50.380001068115234px;
$unnamed-line-spacing-60-470001220703125: 60.470001220703125px;
$unnamed-line-spacing-41-97999954223633: 41.97999954223633px;
$unnamed-line-spacing-16-8799991607666: 16.8799991607666px;
$unnamed-line-spacing-19-440000534057617: 19.440000534057617px;
$unnamed-line-spacing-18-75: 18.75px;
$unnamed-line-spacing-21-6: 21.6px;
$unnamed-line-spacing-19-44: 19.44px;
$unnamed-text-transform-uppercase: uppercase;

// Block
$block-padding: 48px 0;

// Item
$item-list-gap: 16px;
$item-gap: 16px;
$item-gap-horizontal: 20px;
$item-content-gap: 16px;

// Border
$border-radius-66: 66px;
$border-radius-55: 55px;
$border-radius-44: 44px;
$border-radius-6: 6px;
$border-radius-4: 4px;
$border-radius-8: 8px;
$border-radius-12: 12px;
$border-radius-40: 40px;

// Animation
$transition: 300ms ease-in;

// musi być zawsze
$border-color:$color-gray-300;
// musi być zawsze
$color_err: #D81826;
