a {
    text-decoration: none;
    color:#000; // kolor disabled

    &:link, &:visited {
        color:#555; // kolor normalny
    }

    &:hover, &.hover {
        color:#4E7D1E;
        text-decoration: underline;
    }
}

.btn1 {
    //display:inline-block;
    //padding: 10px 40px;
    //border:2px solid $color2;
    //background-color:$color2;
    //color:#000;
    //border-radius: 25px;
    //text-decoration: none;
    //
    //font-size:18px;
    //font-weight:400;
    //
    //transition: ease 0.2s;
    //
    //&:hover, &.hover {
    //    text-decoration: none;
    //    color:#000;
    //
    //    -webkit-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    //    -moz-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    //    box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    //}
    //
    //&.disabled, &:disabled {
    //    background-color:#eee;
    //    border-color:#ddd;
    //}


    padding: 0 24px;
    transition: $transition;
    font: normal normal bold 14px/19px Lato;
    border-radius: 24px !important;
    border: 2px solid $theme-main;
    line-height: 44px;
    &:hover{
        filter: none !important;
        background-color: $opacity-main !important;
    }
    &:hover{
        filter: brightness(120%) saturate(120%);
        background-color: transparent !important
    }
    background-color: $theme-cta;
    color: $theme-cta-font !important;

    &:hover{
        background-color: $theme-cta-600;
    }
}

.btn12 {
    display:inline-block;
    padding: 10px 40px;
    border:2px solid $color2;
    background-color:$color2;
    color:#000;
    border-radius: 25px;
    text-decoration: none;

    font-size:18px;
    font-weight:400;

    transition: ease 0.2s;

    &:hover, &.hover {
        text-decoration: none;
        color:#000;

        -webkit-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    }

    &.disabled, &:disabled {
        background-color:#eee;
        border-color:#ddd;
    }
}

.btn2 {
    display:inline-block;
    padding: 10px 40px;
    border:2px solid $color3;
    background-color:$color3;
    color:#000;
    border-radius: 25px;
    text-decoration: none;

    font-size:18px;
    font-weight:400;

    transition: ease 0.2s;

    &:hover, &.hover {
        text-decoration: none;
        color:#000;

        -webkit-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    }

    &.disabled, &:disabled {
        background-color:#eee;
        border-color:#ddd;
    }
}

.btn3 {
    display:inline-block;
    padding: 10px 40px;
    border:2px solid $color4;
    background-color:$color4;
    color:#000;
    border-radius: 25px;
    text-decoration: none;

    font-size:18px;
    font-weight:400;

    transition: ease 0.2s;

    &:hover, &.hover {
        text-decoration: none;
        color:#000;

        -webkit-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    }

    &.disabled, &:disabled {
        background-color:#eee;
        border-color:#ddd;
    }
}

.btn3_dark {
    display:inline-block;
    padding: 10px 40px;
    border:2px solid $color4;
    background-color:$color4;
    color:#000;
    border-radius: 25px;
    text-decoration: none;

    font-size:18px;
    font-weight:400;

    transition: ease 0.2s;

    &:hover, &.hover {
        text-decoration: none;
        color:#000;

        -webkit-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    }

    &.disabled, &:disabled {
        background-color:#eee;
        border-color:#ddd;
    }
}

.btn2_dark {
    display:inline-block;
    padding: 10px 40px;
    border:2px solid $color3;
    color:$color3!important;
    border-radius: 25px;
    background:transparent;
    text-decoration: none;

    font-size:18px;
    font-weight:400;

    transition: ease 0.2s;

    &:hover, &.hover {
        text-decoration: none;
        color:#000!important;
        background-color:$color3;

        -webkit-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        -moz-box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
        box-shadow: 0px 0px 21px -3px rgba(0,0,0,0.4);
    }

    &.disabled, &:disabled {
        background-color:#eee;
        border-color:#ddd;
        color:#aaa!important;
    }
}
