//.new-cp {
//    position:fixed;
//    bottom:0;
//    left:0;
//    width:100%;
//    padding:40px;
//    background-color:#ffffff;
//    color:#000;
//    font-size:14px;
//    z-index: 10;
//    border-top:2px solid #aaaaaa;
//    @media only screen and (max-width: 767px) {
//        padding: 20px;
//        > div{
//            &:first-child{
//                display: none;
//            }
//        }
//    }
//    svg {
//        width:100px;
//        height:100px;
//        path {
//            fill:#aaaaaa;
//        }
//    }
//
//    h3, p {
//        padding-bottom:15px;
//    }
//
//
//    button {
//        border:1px solid #aaaaaa;
//        border-radius:5px;
//        background:transparent;
//        color:#000;
//        padding:10px 20px;
//        transition: 0.2s ease;
//
//        &:hover {
//            background-color:#aaaaaa;
//            color:#ffffff;
//        }
//    }
//
//    > div {
//        display:inline-block;
//        margin-right: -4px;
//        vertical-align: top;
//        @media only screen and (max-width: 767px) {
//            display: block;
//            margin-right: 0;
//            font-size: 10px;
//        }
//    }
//
//    .new-cp-content {
//        padding-left:30px;
//        width:calc(100% - 100px);
//        @media only screen and (max-width: 767px) {
//            width: 100%;
//            padding-left: 0;
//        }
//    }
//
//    button {
//        margin-right: 10px;
//    }
//
//    .new-cp-content-settings-options-one-cookie {
//        cursor: pointer;
//        transition: 0.2s ease;
//
//        &:hover {
//            opacity:0.6;
//        }
//
//        > div {
//            display:inline-block;
//            margin-right: -4px;
//            vertical-align: top;
//
//            &.new-cp-content-settings-options-one-cookie-checkbox {
//                width:20px;
//                margin-right: 10px;
//
//                > div {
//                    border:1px solid #aaaaaa;
//                    border-radius: 50%;
//                    width:20px;
//                    height:20px;
//                    position:relative;
//                }
//            }
//
//            &.new-cp-content-settings-options-one-cookie-desc {
//                width:calc(100% - 30px);
//                padding-top:2px;
//                max-height: 150px;
//                overflow-y: scroll;
//                overflow-x: hidden;
//                &::-webkit-scrollbar {
//                    width: 4px;
//                }
//                &::-webkit-scrollbar-thumb {
//                    background: #888;
//                }
//            }
//        }
//
//        &.on {
//            .new-cp-content-settings-options-one-cookie-checkbox {
//                > div {
//                    &:after {
//                        content:"";
//                        position:absolute;
//                        left:2px;
//                        top:2px;
//                        width:14px;
//                        height:14px;
//                        background-color:#aaaaaa;
//                        border-radius: 50%;
//                    }
//                }
//            }
//        }
//
//        &.disabled {
//            .new-cp-content-settings-options-one-cookie-checkbox {
//                > div {
//                    opacity:0.2;
//                }
//            }
//        }
//    }
//}
@mixin fill{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
}
@mixin bg_pos{
    background-repeat: no-repeat;
    background-position: center;
}
.new_cp{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 999999999;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    label{
        font-weight: 400 !important;
    }
    > div{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -ms-flex-align: center;
        -webkit-align-items: center;
        -webkit-box-align: center;

        align-items: center;
        @media only screen and (max-width: 767px) {
            display: block;
            padding: 15px;
        }
    }
}
.new_cp_content{
    position: relative;
    color: #00324B;
    //width: 790px;
    width: 690px;
    margin: 0 auto;
    border-radius: 10px;
    padding: 40px;
    background-color: #FFFFFF;
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    @media only screen and (max-width: 767px) {
        width: 100%;
        height: calc(100vh - 30px);
        overflow-y: scroll;

    }
        h3{
        font-size: 28px;
        line-height: 40px;
        letter-spacing: -0.4px;
        color: #151B28;
        margin-bottom: 20px;
        font-weight: 700;
    }
}

.cp_icon{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F4F7FB;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    justify-content: center;
    margin: 0 auto 19px auto;
    svg{
        width: 24px;
        path{
            fill: $theme-main
        }
    }
}

.new-cp-content-buttons, .new-cp-content-settings-buttons{
    padding-top: 26px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;
    @media only screen and (max-width: 767px) {

        display: block;
        a,button{
            width: 100%;
            text-align: center;
            margin-bottom: 14px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}

.cp_neutal_btn{
    background-color: #F4F7FB;
    border-radius: 5px;
    display: inline-block;
    padding: 12px 24px;
    border: 0;
    line-height: 48px;
    color: #000000;
    font-weight: 700;
    font: normal normal bold 14px/24px Lato;
    &:hover{
        text-decoration: underline;
    }
}

.new-cp-content-settings-options-one-cookie {
    cursor: pointer;
    transition: 0.2s ease;
    display: flex;
    &:hover {
        //opacity:0.6;
    }

    > div {
        display:inline-block;
        margin-right: -4px;
        vertical-align: top;

        &.new-cp-content-settings-options-one-cookie-checkbox {
            width:20px;
            margin-right: 10px;

            > div {
                border:1px solid #F4F7FB;
                width:20px;
                height:20px;
                position:relative;
                background-color:#F4F7FB;
                border-radius: 6px;
                overflow: hidden;
            }
        }

        &.new-cp-content-settings-options-one-cookie-desc {
            width:calc(100% - 30px);
            padding-top:2px;
        }
    }

    &.on {
        .new-cp-content-settings-options-one-cookie-checkbox {
            > div {
                background-color: $theme-main;;
                border-color:  $theme-main;;
                &:after {
                    content:"";
                    @include fill;
                    background-color: $theme-main;
                    @include bg_pos;
                    background-image: url("./../img/check.svg");
                    background-size: 10px auto;
                }
            }
        }
    }

    &.disabled {
        .new-cp-content-settings-options-one-cookie-checkbox {
            > div {
                //opacity:0.2;
            }
        }
    }
}
.new-cp-content-settings-options{
    padding-top: 26px;
}
