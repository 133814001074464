@import "plugins/bootstrap/bootstrap";

// Tags
section{
    padding: $block-padding;
}

// Grid
.vertical_block{
    display: grid;
}

// Gap
.gap_block{
    gap: 48px;
}

.phone_item{
    rect{
        fill: transparent !important;
    }
}

.calendar_heading{
    path, rect{
        fill: $theme-main;
    }
}
input{
    &.btn{
        height: 48px;
        font-weight: 700 !important;
        padding-top: 0;
        padding-bottom: 0;
    }
}
// Btns
.btn{
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 12px 24px;
    width: fit-content;
    height: fit-content;
    transition: $transition;
    box-shadow: unset;
    border: unset;
    background-color: transparent;
    font: normal normal bold 14px/19px Lato;
    line-height: 48px !important;
    &:hover{
        filter: brightness(120%) saturate(120%);

    }
    &.green_btn{
        position: relative;
        z-index: 2;
        background-color: transparent !important;
        &:hover{
            &:after{
                transform: scale(0.9);
            }
        }
        &:after{
            z-index: -1;
            content: '';
            position: absolute;
            top: 0px;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: $theme-cta;
            transition: 0.3s all ease;
        }

        color: $theme-cta-font !important;

        &:hover{
            &:after{
                //filter: brightness(120%) saturate(120%) !important;
                background-color: $theme-cta !important;
            }
        }
    }


    &.blue_btn{
        background-color: $theme-main !important;
        color: $color-white;
        &:hover{
            //background-color:$theme-light-dark;
            text-decoration: underline;
            //background-color: $theme-cta;
            background-color: $theme-main !important;
            color: $color-white;
        }
    }


    &.white_btn{
        background-color:$color-white;
        color: $color-gray-600;
        border: 1px solid $color-gray-200;

        &:hover{
            background-color: $color-gray-300;
        }
    }


    &.gray_btn{
        background-color:$color-gray-200;
        color: $color-gray-600;

        &.open_mobile_menu,
        &.toggle_search{
            padding: 12px;
        }

        &:hover{
            background-color: $color-gray-300;
        }
    }

    &.pagination_btn{
        display: grid;
        place-items: center;
        padding: unset;
        height: 48px;
        width: 48px;
        background-color:transparent;
        color: $color-gray-600;
        text-align: center;
        font: normal normal bold 14px/19px Lato;

        &.active{
            box-shadow: unset;
            background-color: $theme-cta;
            color: $color-white;
        }

        &:hover{
            background-color: $color-gray-200;
        }
    }
}

.carousel_arrow_right,
.carousel_arrow_left{
    transition: $transition;
}

.carousel_arrow_right:hover,
.carousel_arrow_left:hover{
    cursor: pointer;
}

// Blocks
.block_heading-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    @media only screen and (max-width: 767px) {
        flex-wrap: nowrap;
        display: block;
        .btn{
            margin-top: 16px;
        }
    }
    .heading{
        h2{
            &:not(h2){
                font-size: 24px;
            }
        }
        *{
            margin: 0;
        }

        img{
            width: 16px ;
            height: 16px;
            object-fit: contain;
        }
    }
}

.dark_block{


    .heading,
    .block_heading-wrapper{
        *{
            color: $color-white;
        }
    }

    .checkbox-wrapper{
        display: grid;
        align-items: center;
        grid-template-columns: auto 1fr;
        gap: 8px;
        color: $color-gray-300;
        font: normal normal normal 14px/19px Lato;

        .field_checkbox{
            display: grid;
            align-items: center;

            .field_checkbox_box{
                display: grid;
                align-items: center;
                height: fit-content;

                .field_checkbox_input{
                    padding: 0;
                }

                .checkbox_image_box{

                    &> div{
                        border: 2px solid $color-gray-400;
                        height: 16px;
                        width: 16px;
                        background-color: transparent;
                        &.yes{
                            background-color: $color-white;
                            box-shadow: inset 0px 0px 0px 2px $theme-dark;
                        }
                    }


                }
            }
        }

        a{
            text-decoration: underline;
            transition: $transition;
            color: $color-gray-300;

            &:hover{
                color: $theme-cta;
            }
        }
    }
}

.gray_block{
    background-color: $color-gray-100;
    &:has(.carousel_elements_parent){
        background-color: transparent;
    }
}

.shortcuts_block.gray_block{
    margin-bottom: 0 !important;
}
// Items
.item{
    position: relative;

    h3.heading{
        color: $color-gray-900;
        font: normal normal bold 18px/24px $unnamed-font-family-lato;
    }

    .content{
        color: $color-gray-600;
        font: normal normal normal 14px/22px $unnamed-font-family-lato;
    }

    .posted{
        display: grid;
        align-items: center;
        grid-template-columns: 13px 1fr;
        gap: 6px;
        color: $color-gray-600;
        font: normal normal 900 10px/14px $unnamed-font-family-lato;
        margin-bottom: 12px;

        img{
            max-height: 13px;
            max-width: 13px;
        }
    }

    .category{
        display: grid;
        align-items: center;
        grid-template-columns: 8px 1fr;
        gap: 8px;
        color: $color-gray-600;
        font: normal normal 900 10px/14px $unnamed-font-family-lato;
        letter-spacing: 0.49px;
        text-transform: uppercase;
        min-height: 14px;
        margin-bottom: 5px;
        img{
            max-height: 8px;
            max-width: 8px;
        }
    }

    .additional_info>*{
        display: grid;
        align-items: center;
        grid-template-columns: 12px 1fr;
        gap: 8px;
        font-size: 12.5px;
        line-height: 18.75px;
        img{
            height: 13px;
            width: 13px;
            object-fit: cover;
        }
    }

    .phones_list{
        display: grid;
        gap: 8px;

        .phone_item{
            display: grid;
            align-items: center;
            grid-template-columns: auto 1fr;
            gap: 8px;
        }
    }

    .location{
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
    }
}

//Links
a.item:hover{
    text-decoration: unset;
}

//Form
.default_form{

    .select2-container{
        display: grid;
        align-items: center;
        justify-items: center;
        height: 100% !important;
        width: 100% !important;

        .selection{
            display: grid;
            align-items: center;
            justify-items: center;
            height: 100%;
            width: 100%;

            .select2-selection{
                display: grid;
                align-items: center;
                justify-items: flex-start;
                height: 100%;
                width: 100%;
                //padding: 16px 36px 16px 16px;
                padding: 9px 36px 9px 16px;
                span{
                    padding: 0;
                }
                .select2-selection__arrow{
                    top: 50%;
                    right: 20px;
                    transform: translate(0, -50%);
                }
            }
        }
    }
    .select2-container--default {
        .select2-selection--single {
            .select2-selection__arrow {
                b{
                    margin-top: 0;
                }
            }
        }
    }
}

//Bootstrap
.relative{
    position: relative;
}

.split_block{
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: fit-content;
}

.gap_24{
    gap: 24px;
}

.row{

    >div[class^='col']{
        .btn{
            @media only screen and (max-width: 767px) {
                display: inherit !important;
                margin-left: auto;
            }
        }
        >.block_heading-wrapper{
            padding-bottom: 32px;
            padding-bottom: 0;
        }
    }
}

ul.item-list{
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 0;
    list-style-type: none;
}


// Table
.table-warpper{
    max-width: 100%;
    overflow-x: scroll;
}

table{
    //border-radius: $border-radius-6 $border-radius-6 0 0;
    overflow: hidden;
    width: 100%;
    border: 0;
    //font-size: 14.4px;
    font-size: 18px;
    line-height: 20px;
    border-collapse: separate;
    border-radius: 0 !important;
    border-left: 1px solid #0D1E40;
    border-top: 1px solid #0D1E40;
    tr{

        td,
        th{
            padding: 8px 8px;
        }
    }
    th{
        background-color: $color-gray-700;
        color: $color-white;
        border: 0;

        //&:first-child{
        //    border-top-left-radius: 4px;
        //}
        //&:last-child{
        //    border-top-right-radius: 4px;
        //}
    }
    td{
        border: 1px solid #0D1E40 !important;
        border-left: 0 !important;
        border-top: 0 !important;
        //&:first-child{
        //    border-bottom-left-radius: 4px;
        //}
        //&:last-child{
        //    border-bottom-right-radius: 4px;
        //}
    }
    thead{


        tr{
            border: 1px solid #0D1E40 !important;

        }
    }

    tbody{
        background-color: $color-gray-100;
        //th{
        //    background-color: $color-gray-100;
        //    border-right: 1px solid #0D1E40;
        //    color:#151B28;
        //    border-bottom: 1px solid #0D1E40;;
        //    &:last-child{
        //        border-right: 0;
        //    }
        //}
        tr{
            border-right: 0;
            border-bottom: 1px solid $color-gray-200;
            &:last-child{
                border-bottom: 0;
            }
        }
    }
}


//Breadcrumbs
.subpage_top{
    //padding: 24px 0 16px;
    &+section{
        padding-top: 0;
    }
}

.breadcrumbs{

    ul{
        display: flex;
        margin: 0;
        padding: 0;
        list-style-type: none;
        @media only screen and (max-width: 767px) {
            display: block;
        }
        li{
            display: flex;
            @media only screen and (max-width: 767px) {
                display: inline-block;
                vertical-align: top;
                margin-left: 0 !important;
                margin-right: 5px;
                a,p{
                    display: inline-block;
                    vertical-align: top;
                }
            }
            &,&*{
                color: $color-gray-600;
                font: normal normal normal 10px/14px Lato;
                transition: $transition;
            }


            &:not(:first-child){
                margin-left: 4px;

                &::before{
                    content: '';
                    display: block;
                    background-image: url('/svg/breadcrumbs_next.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    padding: 6px;
                    margin-right: 4px;
                    @media only screen and (max-width: 767px) {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}

.newest_info_block-wrapper {
    .item-list {
        ul {
            > li {
                .item {
                    @media only screen and (max-width: 767px) {
                        -ms-flex-align: center;
                        -webkit-align-items: center;
                        -webkit-box-align: center;

                        align-items: center;
                    }
                }
            }
        }
    }
}

.downloads_item img{
    width: 20px !important;
    height: 20px !important;
}
