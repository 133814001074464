@import "main/variables";

& {
    font-family: $font-family;
    font-size: 16px;
    line-height: 20px;

    //> * {
    //    margin:0;
    //    padding:0;
    //    //padding-bottom:20px;
    //   margin-bottom:20px;
    //}
}
p,ul,ol,img,.table_scroll,>div{
    margin-bottom: 20px;
    &:last-child{
        margin-bottom: 0;
    }
}
caption{
    color: $color-gray-900;
}
h1, .h1 {
    font-family: $unnamed-font-family-lato;
    font-style: $unnamed-font-style-normal;
    //font-weight: $unnamed-font-weight-900;
    font-weight: 700;
    font-size: $unnamed-font-size-45;
    line-height: $unnamed-line-spacing-60-470001220703125;
    letter-spacing: $unnamed-character-spacing--0-49;
    color: $color-gray-900;
}
//
// .h2 {
//    //font-family: $unnamed-font-family-lato;
//    //font-style: $unnamed-font-style-normal;
//    ////font-weight: $unnamed-font-weight-900;
//    //font-weight: 700;
//    //font-size: $unnamed-font-size-37;
//    //line-height: $unnamed-line-spacing-50-380001068115234;
//    //letter-spacing: $unnamed-character-spacing--0-49;
//    //color: $color-gray-900;
//    //@media only screen and (max-width: 767px) {
//    //    font-size: $unnamed-font-size-22;
//    //    line-height: $unnamed-line-spacing-34-9900016784668;
//    //}
//     font-size: 28px;
//     line-height: 42px;
//     font-weight: 700;
//     //margin-bottom: 5px;
//}
//
// .h3 {
//    //font-family: $unnamed-font-family-lato;
//    //font-style: $unnamed-font-style-normal;
//    ////font-weight: $unnamed-font-weight-900;
//    //font-weight: 700;
//    //font-size: $unnamed-font-size-31;
//    //line-height: $unnamed-line-spacing-41-97999954223633;
//    //letter-spacing: $unnamed-character-spacing--0-5;
//    //color: $color-gray-900;
//     font-size: 26px;
//     line-height: 39px;
//     font-weight: 700;
//     //margin-bottom: 5px;
//}
//
// .h4 {
//    //font-family: $unnamed-font-family-lato;
//    //font-style: $unnamed-font-style-normal;
//    ////font-weight: $unnamed-font-weight-900;
//    //font-weight: 700;
//    //font-size: $unnamed-font-size-26;
//    //line-height: $unnamed-line-spacing-34-9900016784668;
//    //letter-spacing: $unnamed-character-spacing--0-4;
//    //color: $color-gray-900;
//     font-size: 22px;
//     line-height: 33px;
//     font-weight: 700;
//     //margin-bottom: 5px;
//}
//
// .h5 {
//    //font-family: $unnamed-font-family-lato;
//    //font-style: $unnamed-font-style-normal;
//    ////font-weight: $unnamed-font-weight-900;
//    //font-weight: 700;
//    //font-size: $unnamed-font-size-22;
//    //line-height: $unnamed-line-spacing-29-15999984741211;
//    //letter-spacing: $unnamed-character-spacing--0-4;
//    //color: $color-gray-900;
//     font-size: 18px;
//     line-height: 27px;
//     font-weight: 700;
//     //margin-bottom: 5px;
//}
//
// .h6{
//    //font-family: $unnamed-font-family-lato;
//    //font-style: $unnamed-font-style-normal;
//    ////font-weight: $unnamed-font-weight-900;
//    //font-weight: 700;
//    //font-size: $unnamed-font-size-18;
//    //line-height: $unnamed-line-spacing-24-299999237060547;
//    //letter-spacing: $unnamed-character-spacing--0-29;
//    //color: $color-gray-900;
//     font-size: 18px;
//     line-height: 27px;
//     //margin-bottom: 5px;
//}
h2{
    //font-family: $unnamed-font-family-lato;
    //font-style: $unnamed-font-style-normal;
    ////font-weight: $unnamed-font-weight-900;
    //font-weight: 700;
    //font-size: $unnamed-font-size-26;
    //line-height: $unnamed-line-spacing-34-9900016784668;
    //letter-spacing: $unnamed-character-spacing--0-4;
    //color: $color-gray-900;
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;
    //margin-bottom: 5px;
}
$unnamed-font-size-222: 22px;
h3{
    //font-family: $unnamed-font-family-lato;
    //font-style: $unnamed-font-style-normal;
    ////font-weight: $unnamed-font-weight-900;
    //font-weight: 700;
    //font-size: $unnamed-font-size-222;
    //line-height: $unnamed-line-spacing-29-15999984741211;
    //letter-spacing: $unnamed-character-spacing--0-4;
    //color: $color-gray-900;
    font-size: 26px;
    line-height: 39px;
    font-weight: 700;
    //margin-bottom: 5px;
}
h4{
    //font-family: $unnamed-font-family-lato;
    //font-style: $unnamed-font-style-normal;
    ////font-weight: $unnamed-font-weight-900;
    //font-weight: 700;
    //font-size: $unnamed-font-size-18;
    //line-height: $unnamed-line-spacing-24-299999237060547;
    //letter-spacing: $unnamed-character-spacing--0-29;
    //color: $color-gray-900;
    font-size: 22px;
    line-height: 33px;
    font-weight: 700;
    //margin-bottom: 5px;
}
h5{
    //font-family: $unnamed-font-family-lato;
    //font-style: $unnamed-font-style-normal;
    ////font-weight: $unnamed-font-weight-900;
    //font-weight: 700;
    //font-size: 16px;
    //line-height: 20px;
    //letter-spacing: $unnamed-character-spacing--0-29;
    //color: $color-gray-900;
    font-size: 18px;
    line-height: 27px;
    font-weight: 700;
    //margin-bottom: 5px;
}
h6{
    //font-family: $unnamed-font-family-lato;
    //font-style: $unnamed-font-style-normal;
    ////font-weight: $unnamed-font-weight-900;
    //font-weight: 700;
    //font-size: 14px;
    //line-height: 18px;
    //letter-spacing: $unnamed-character-spacing--0-29;
    //color: $color-gray-900;
    font-size: 18px;
    line-height: 27px;
    //margin-bottom: 5px;
}
h1,h2,h3,h4,h5,h6{
    margin-bottom: 25px;
    &:last-child{
        margin-bottom: 0;
    }
}
.err_txt {
    color:$color_err;
}

ol{
    margin-left: 20px;

    ol, ul {
        margin-top: 13px;
    }
}
//
//ul{
//    list-style-type: none;
//    padding: 0;
//
//    li{
//
//
//
//        padding: 0;
//
//        &::before{
//            content: '';
//            height: 6px;
//            width: 6px;
//            border-radius: 50%;
//            background-color: $color-gray-900;
//        }
//        ul{
//
//
//            li{
//                &::before{
//                    border-radius: unset;
//                }
//
//                li{
//                    &::before{
//                        background-color: unset;
//                        border: 1px solid $color-gray-900;
//                    }
//                }
//            }
//        }
//    }
//}
//ul{
//   > li{
//       display: flex;
//   }
//}
ul,ol{
    > li{
        > ul, > ol{
            margin-top: 0;
            padding-top: 13px;
        }
    }
}
ul{
    padding-left: 18px;
    list-style: disc;
    //> li{
    //    > ul {
    //        padding-top: 13px;
    //    }
    //}
    li{
        &:last-child{
            padding-bottom: 0;
        }
        &::marker{
            color: $color-gray-900;
        }
    }
}
ul, ol {
    margin-left: 0;
    padding-left: 18px;
    padding-bottom: 0 !important;
    li{
        margin-bottom: 13px;
    }
    color: $color-gray-900;
    //font-size: 18px;
    //line-height: 27px;
    ul, ol{
        margin-left: 0 !important;
        padding-bottom: 0;
        padding-left: 18px;
    }
}

table{
    @media only screen and (max-width: 767px) {
        width: 100% !important;
    }
}

a{
    color: #337ab7 !important;
    text-decoration: underline ;
    &:hover{
        text-decoration: none !important;
    }
}

img{
    border-radius: 4px;
}
