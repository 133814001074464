.page.mourning {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
}

// nie ruszać ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// nie ruszać ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// nie ruszać ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
.default_page_elements {
    padding-top:50px;
    padding-bottom:50px;

    .default_title {
        padding-bottom:20px;
        border-bottom:1px solid #dbdbdb;
        margin-bottom:20px;

        h3 {
            margin:0;
            padding:0;
        }

        p {
            padding-top:10px;
            font-size:14px;
            line-height:18px;
        }
    }

    .buttons_and_elements {
        width:100%;
        padding-bottom:50px;
        .default_buttons_table {
            display:table;
            width:100%;
            .default_one_row, .default_title_row {
                display:table-row;

                &.dark {
                    .default_cell_title {
                        color:#fff;
                    }
                }

                &.default_title_row {
                    > div {
                        text-align: center;
                        font-size:15px;
                        color:#9C9C9C;
                    }
                }

                > div {
                    &.default_cell_title {
                        font-size:15px;
                        text-align: left;
                        padding-left:20px;

                        span {
                            color:#9C9C9C;
                        }
                    }

                    width:25%;
                    display:table-cell;
                    padding:15px;
                    text-align: center;
                }
            }
        }
    }

    .color_list {
        width:100%;
        padding-bottom:50px;

        h3 {
            padding-bottom:20px;
        }

        .color_box {
            width: 25%;
            margin-right: -4px;
            display: inline-block;
            vertical-align: top;
            margin-bottom:30px;

            p {
                position:relative;
                font-size: 18px;
                color:#000;
                bottom:6px;

                span {
                    position:absolute;
                    top:100%;
                    left:0;
                    width:100%;
                    color:#9C9C9C;
                }
            }

            .show_color_box {
                width:100px;
                height:100px;
                display:inline-block;
                vertical-align: middle;
                border-radius: 10px;
                margin-right: 20px;
            }

            .show_color_txt {
                display:inline-block;
                vertical-align: middle;
                width:calc(100% - 120px);
            }
        }
    }

    .default_form {
        .default_one_filed {
            padding-bottom:30px;

            > p {
                color:#9C9C9C;
                border-bottom:1px solid #f3f3f3;
                padding-bottom:7px;
                margin-bottom:10px;
            }
        }
    }
}
